// Extra small devices (portrait phones, less than 576px)

@media
  (max-width : 575.98px) {
  .off-canvas-nav {
    right : -100vw;
    width : 100vw;
  }

  .btn-mobile {
    display         : -webkit-flex;
    display         : -ms-flex;
    display         : flex;
    height          : 48px !important;
    padding-right   : 10px !important;
    padding-left    : 6px !important;
    align-items     : center;
    font-size       : 11px !important;
    justify-content : space-between;
  }
  .event-back-wrapper{
    width:100%;
    .event-back{
      width:100%;
      text-align: center;
    }
  }

  .header-block.header-services {
    min-height:  0;
  }

  .header-block .services-subtitle {
    font-size: 25px;
    width: 80%;
    margin-top: 10px;
    line-height: 35px;
  }

  .case-block {
    padding: 0;
  }
  .btn-arrow {
    &--secondary-border {
      font-size: 14px !important;
      width: 280px;
      padding-bottom: 18px;
    }
  }

  }

// Small devices (landscape phones, less than 768px)

@media
  (max-width : 1199.98px) {
    .case-cta {
      .left,
      .right {
        .inner {
          padding : 75px 35px;
        }
      }
    }
  }

@media
  (max-width : 767.98px) {
  body {
    max-width  : 100vw;
    overflow-x : hidden;
  }

  .navbar {
    height     : 80px;
    margin-top : 0;
    box-shadow : none;

    .navbar-brand {
      width : 100px;
    }
  }

  .header-block {
    min-height : 210px;
    margin-top : 0;
  }

  h1 {
    font-size : 22px !important;
  }

  h2 {
    font-size   : 22px;
    line-height : 28px;

    &.quote {
      line-height : 30px;
    }
  }

  .home__diensten {
    margin-top : 0;
    padding    : 20px;
    box-shadow : none;
  }

  .btn-arrow {
    padding: 10px 35px 10px 16px !important;
    font-size      : 16px !important;

    img {
      right  : -33px !important;
      height : 48px !important;
    }
  }

  .bg-element {
    background-size : auto 33%;

    &.bottom-left {
      background-position : right -50px center;
    }
  }

  .blog-grid {
    align-items     : center;
    flex-direction  : column;
    justify-content : flex-start;

    .blog-item {
      padding      : 32px 24px !important;
      border-top   : 2px solid rgba($black, .8);
      border-right : 0;
      align-items  : flex-start;

      .cms,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        text-align : left !important;
      }

      &:first-child {
        border-top : 0;
      }
    }
  }

  .map {
    padding-top : 50% !important;
  }

  .case-cta {
    .left,
    .right {
      .inner {
        padding : 50px 40px;
      }
    }
  }

  .cta-block {
    padding : 50px 40px !important;
  }

  .chat-bot {
    .speech-bubble {
      right  : 16px;
      bottom : -60px;
    }
  }

  .services-grid {
    margin-top : 32px !important;

    .service-item {
      padding-top : 50% !important;
      flex        : 0 0 100% !important;
    }
  }

  .about-grid-bg {
    .about-grid {
      .about-item {
        margin-right : 0 !important;
        flex         : 0 0 100% !important;

        .inner {
          padding : 35px 45px !important;
        }

        &:last-child {
          margin-bottom : 0 !important;
        }
      }
    }
  }

  .adviesraad-slider {
    position : relative;

    .slick-slide {
      margin-left : 18px;
    }

    .slick-list {
      margin-left : -18px;
    }

    .slider-controls {
      display         : flex;
      position        : absolute;
      top             : calc(50% - 21px);
      right           : -11px;
      left            : -11px;
      align-items     : center;
      justify-content : space-between;

      a {
        z-index : 999;

        &.prev-slide {
          transform : rotate(180deg);
        }
      }
    }

    .slide-wrapper {
      .adviesraad-block {
        padding-right  : 32px;
        padding-bottom : 32px;
        padding-left   : 32px;

        .cms {
          font-size   : 15px;
          line-height : 25px;
        }
      }
    }
  }

  .voordelen-block {
    .wrapper {
      align-items     : center;
      flex-direction  : column;
      justify-content : flex-start;

      .voordeel-item {
        margin-bottom : 40px;

        &:last-child {
          margin-bottom : 0;
        }
      }
    }

    .btn {
      bottom      : -20px;
      margin-left : -8px;
      transform   : scale(.85);
    }
  }

  .btn-scale-mobile {
    margin-left : -8px;
    transform   : scale(.85);
  }
}

// Medium devices (tablets, less than 992px)
@media
  (max-width : 991.98px) {
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right : 15px;
    padding-left  : 15px;
  }

  .navbar {
    padding : 0 16px;
  }

  h1 {
    font-size : 38px; //42px
  }

  .case-block {
    position : relative;

    .image {
      display  : block;
      position : relative;
      width    : 100%;
      height   : 400px;

      &.left {
        right : auto;
        left  : auto;
      }

      &.right {
        right : auto;
        left  : auto;
      }
    }

    .bg-block {
      display : none;
    }

    .container {
      max-width        : 100%;
      margin           : 0;
      padding-right    : 30px;
      padding-left     : 30px;
      background-color : $blue;
    }
  }

  .references-grid {
    .reference-item {
      max-width     : 33%;
      height        : 170px;
      border        : 0;
      flex          : 0 0 33.333%;
    }
  }

  .person-img {
    position      : relative;
    bottom        : auto;
    margin-bottom : calc(0 - #{3rem});
  }

  .jobs-img {
    position      : relative;
    bottom        : auto;
    margin-bottom : calc(0 - #{1.5rem});
  }

  .blog-grid {
    flex-wrap : wrap;

    .blog-item {
      padding : 60px 30px;
      flex    : 1 0 50%;

      .date {
        color     : rgba($black, .6);
        font-size : 16px;
      }

      &:last-child {
        border-top   : 2px solid rgba($black, .8);
        border-right : 0;
      }

      &:nth-child(2) {
        border-right : 0;
      }
    }
  }

  .header-block {
    h4 {
      font-size   : 18px;
      line-height : 26px;
    }
  }

  .speech-bubble {
    position : absolute;
    right    : 0;
    bottom   : -95px;
  }

  footer {
    .footer-menu {
      align-items     : flex-start;
      flex-direction  : column;
      justify-content : flex-start;
    }

    .footer-socials {
      justify-content : flex-start;
    }
  }

  .map {
    padding-top : 33%;
  }

  .case-cta {
    .left {
      border-right  : 0;
      border-bottom : 2px solid rgba($black, .8);
    }
  }

  .cta-block {
    padding : 60px;
  }

  .about-grid-bg {
    .about-grid {
      .about-item {
        flex-basis : calc(50% - 1.333px);
        .inner {
          padding : 35px 45px;
        }
      }
    }
  }

  .services-grid-blue {
    margin-top: 35px;

  }

  .services-grid {
    display         : flex;
    margin-top      : 35px;
    align-items     : flex-start;
    flex-wrap       : wrap;
    justify-content : space-between;

    .service-item {
      margin-bottom : 20px;
      padding-top   : 30%;
      flex-basis    : calc(50% - 20px);
      flex-grow     : 0;
      flex-shrink   : 1;
      max-width: 100% !important;

      &:last-child {
        margin-right : 20px;
      }

      &:before {
        width       : calc(50% - 20px);
        padding-top : calc(50% - 20px);
      }
    }
  }

  .voordelen-block {
    padding-top    : 40px;
    padding-right  : 40px;
    padding-bottom : 70px;
    padding-left   : 40px;

    .wrapper {
      .voordeel-item {
        flex : 0 0 30%;
      }
    }
  }

  .case-cta {
    .left,
    .right {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

// Large devices (desktops, less than 1200px)
@media(max-width : 1400px) {
  .services-grid {
    .service-item {
      .resp-fix {
        padding : 20px;
        .text {
          max-width : 100%;
          h5 {
            font-size      : 18px;
            letter-spacing : 0;
          }
          img {
            height : 15px;
          }
        }
      }
    }
  }
}

@media
  (min-width : 768px) {
  .header-block {
    .header-breadcrumb {
      max-width : 720px;
    }
  }
}

@media
  (min-width : 992px) {
  .header-block {
    .header-breadcrumb {
      max-width : 960px;
    }
  }
}

@media
  (min-width : 1200px) {
  .header-block {
    .header-breadcrumb {
      max-width : 1140px;
    }
  }
}

@media
  (min-width : 1400px) {
  .header-block {
    .header-breadcrumb {
      max-width : 1386px;
    }
  }
}

@media
  (max-width : 767px) {
  .header-block {
    .header-breadcrumb {
      top       : 15px;
      max-width : 100%;
      padding   : 0 15px;
    }

  }
}

@media only screen and (min-width: 1400px) and (max-width: 1440px) {
  .services-grid {
      .service-item {
          max-width: 15%;
      }
  }
}

@media (min-width: 1400px) {
  .modal-xl {
    max-width: 1340px;
  }
}

/* Btn service */
/* Small devices */

.btn-service {
  position       : relative;
  padding        : 16px 16px;
  border-radius  : 0;
  text-transform : uppercase;
  font-size      : 12px;
  color          : #2f287b;
  border-top     : 2px solid $blue-dark;
  border-bottom  : 2px solid $blue-dark;
  border-left    : 2px solid $blue-dark;

  img {
    z-index  : 5;
    position : absolute;
    top      : -2px;
    right    : -40px;
    height   : 54px;
  }

  &:hover {
    color            : $blue-dark;
    background-color : $white;
    text-decoration  : none;
    cursor           : pointer;
    transition: .3s all ease-in-out;
  }
}

/* Btn service */
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .btn-service {
    font-size: 15px;
    img {
      height: 59px;
    }
  }
}

/* Btn service */
/* Medium devices (tablets, 768px and up) */
@media (min-width: 992px) {
  .btn-service {
    img {
      height: 58px;
    }
  }
}

/* Btn service */
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .btn-service {
    padding: 16px 30px;
    font-size: 18px;
    img {
      height: 63px;
    }
  }
}

/* Btn service detail */
/* Small devices */

.btn-service-detail {
  position       : relative;
  padding        : 5px;
  border-radius  : 0;
  text-transform : uppercase;
  font-size      : 10px;
  color          : #2f287b !important;
  border-top     : 2px solid $blue-dark;
  border-bottom  : 2px solid $blue-dark;
  border-left    : 2px solid $blue-dark;

  img {
    z-index  : 5;
    position : absolute;
    top      : -2px;
    right    : -40px;
    height   : 42px;
  }

  &:hover {
    color            : $white !important;
    background-color : $blue-dark;
    text-decoration  : none;
    cursor           : pointer;
    transition: .3s all ease-in-out;
  }
}

/* Btn service detail */
/* Small devices (425px and up) */
@media (min-width: 425px) {
  .btn-service-detail {
    font-size: 12px;
  }
}

/* Btn service detail */
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .btn-service-detail {
    font-size: 15px;
  }
}

/* Btn service detail */
/* Extra large devices (large desktops, 1440px and up) */
@media (min-width: 1440px) {
  .btn-service-detail {
    padding: 16px 30px 16px;
    font-size: 16px;

    img {
      height: 64px !important;
    }
  }
}

/* Btn case */
/* Small devices */

.btn-case {
  position       : relative;
  padding        : 16px 16px;
  border-radius  : 0;
  text-transform : uppercase;
  font-size      : 12px;
  color          : #2f287b;
  border-top     : 2px solid $blue-dark;
  border-bottom  : 2px solid $blue-dark;
  border-left    : 2px solid $blue-dark;

  img {
    z-index  : 5;
    position : absolute;
    top      : -2px;
    right    : -36px;
    height   : 50px;
  }

  &:hover {
    color            : $white !important;
    background-color : $blue-dark;
    text-decoration  : none;
    cursor           : pointer;
    transition: .3s all ease-in-out;
  }
}

/* Btn service */
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .btn-case {
    font-size: 15px;
    img {
      height: 52px;
    }
  }
}

/* Btn service */
/* Medium devices (tablets, 768px and up) */
@media (min-width: 992px) {
  .btn-case {
    img {
      height: 52px;
    }
  }
}

/* Btn service */
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .btn-case {
    padding: 16px 30px 16px 20px;
    font-size: 16px;
    img {
      height: 54px;
    }
  }
}

.carousel {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 5px;
}

.slider_wrapper {
  margin: auto auto;
  max-width: 70vh;
  overflow: hidden;
}

.slider {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  flex: 0 0 100%;
  max-width: 100%;
  display: block;
  border-radius: 5px;
}

.thumbnails {
  border-radius: 5px;
  margin-top: 10px;
  transition: transform 0.5s ease;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
}

.thumbnail {
  width: 100%;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 5px;
}

.thumbnail.active {
  border-color: #6ab4d6;
}

.load_more {
  width: 100%;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 5px;
  font-size: 1.5rem;
  display: flex;
  color: #9e9e9e;
  padding: 0;
  position: relative;
}

.load_more > img,
.load_more > div {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.load_more > div {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.btns {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  margin-top: 10px;
}

.next {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 50%;
  background-color: #6ab4d6;
  color: white;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 50%;
  background-color: #6ab4d6;
  color: white;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  padding: 16px 30px 16px;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0.04em;
}

.btn-arrow {
  position: relative;
  padding-top: 13px;
  padding-right: 45px;
  padding-bottom: 12px;
}
.btn-arrow--secondary {
  color: #FFFFFF;
  border-top: 2px solid #FFFFFF;
  border-bottom: 2px solid #FFFFFF;
  border-left: 2px solid #FFFFFF;
}
.btn-arrow--secondary:hover {
  color: #2F287B;
  background-color: #FFFFFF;
}
.btn-arrow--primary {
  color: #FFFFFF;
  border-top: 2px solid #2F287B;
  border-bottom: 2px solid #2F287B;
  border-left: 2px solid #2F287B;
  background-color: #2F287B;
}
.btn-arrow--primary:hover {
  color: #2F287B;
  background-color: #FFFFFF;
}
.btn-arrow--secondary-border {
  color: #FFFFFF;
  border-top: 2px solid #2F287B;
  border-bottom: 2px solid #2F287B;
  border-left: 2px solid #2F287B;
}
.btn-arrow--secondary-border:hover {
  color: #2F287B;
  background-color: #FFFFFF;
}
.btn-arrow img {
  z-index: 999;
  position: absolute;
  top: -2px;
  right: -40px;
  height: 59px;
}

.btn-link {
  color: #63ABCC;
  text-decoration: underline;
}
.btn-link i,
.btn-link svg {
  font-size: 13px;
}
.btn-link:hover {
  color: #2F287B;
  text-decoration: none;
}

.custom-block-btn {
  color: #000000;
  text-transform: uppercase;
  text-align: right;
  align-self: flex-end;
}
.custom-block-btn img {
  width: 11px;
  vertical-align: baseline;
}
.custom-block-btn:hover {
  color: #32529D;
  text-decoration: none;
}

.custom-block-btn-back, .custom-block-btn-center {
  color: #000000;
  text-transform: uppercase;
}
.custom-block-btn-back img, .custom-block-btn-center img {
  width: 11px;
  vertical-align: baseline;
}
.custom-block-btn-back:hover, .custom-block-btn-center:hover {
  color: #32529D;
  text-decoration: none;
}

.c-black {
  color: #000000 !important;
}

.c-white {
  color: #FFFFFF !important;
}
.c-white a,
.c-white i,
.c-white p {
  color: #FFFFFF !important;
}

.c-blue-dark {
  color: #2F287B !important;
}

.c-blue {
  color: #32529D !important;
}

.c-blue-light {
  color: #63ABCC !important;
}

.bg-blue-dark {
  background-color: #2F287B;
}

.bg-blue {
  background-color: #32529D;
}

.bg-blue-light {
  background-color: #63ABCC;
}

.bg-grey {
  background-color: #f6f6f6;
}

.text-shadow {
  text-shadow: 1px 1px #44403c;
}

.shadow-md {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
}

.reference h2 {
  font-size: 17px;
}
@media (min-width: 992px) {
  .reference h2 {
    font-size: 22px;
  }
}
@media (min-width: 1440px) {
  .reference h2 {
    font-size: 30px;
  }
}

.big-margin-top {
  margin-top: 45px;
}
@media (min-width: 992px) {
  .big-margin-top {
    margin-top: 160px;
  }
}
@media (min-width: 1386px) {
  .big-margin-top {
    margin-top: 200px;
  }
}

.bg-element {
  background-image: url(/dist/assets/images/bg-element.svg);
  background-repeat: no-repeat;
  background-size: auto 75%;
}
.bg-element.bottom-left {
  background-position: left 20vw bottom -50px;
}
.bg-element.bottom-right {
  background-position: right -35px bottom -150px;
}
.bg-element.large {
  background-size: 66% auto;
}

.quote-mark {
  color: #FFFFFF;
  opacity: 0.5;
  font-family: "Playfair Display", serif;
  font-size: 85px;
}

.person-img {
  position: absolute;
  bottom: calc(0 - 6rem);
}

.jobs-img {
  position: absolute;
  bottom: -3rem;
  display: none;
}
@media (min-width: 992px) {
  .jobs-img {
    display: block;
  }
}

.text-box {
  padding: 85px 55px;
}

.softshadow {
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
}

.services-item-subtitle {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 900;
  line-height: 45px;
}

.services-item-title {
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 900;
  line-height: 45px;
}

.blue-filler {
  width: 100%;
  background-color: #32529D;
}

.grijs {
  filter: grayscale(100%);
}

.header-block {
  display: flex;
  position: relative;
  min-height: 350px;
  margin-top: -80px;
  padding-top: 80px;
  background-color: #63ABCC;
  background-repeat: no-repeat;
  background-position: center 40%;
  background-size: cover;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: flex-start;
}
.header-block.header-services {
  position: relative;
  min-height: 450px;
}
.header-block .services-top-title {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 900;
  line-height: 45px;
}
.header-block .services-top-title span {
  color: #63ABCC;
}
.header-block .services-title {
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 900;
  line-height: 45px;
}
.header-block .services-subtitle {
  color: #FFFFFF;
  font-size: 35px;
  font-weight: 100 !important;
  line-height: 45px;
}
.header-block .header-block-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: hsla(0, 0%, 20%, 0.5);
}
.header-block .cms {
  color: #FFFFFF !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}
.header-block .cms p {
  color: #FFFFFF;
}
.header-block .header-breadcrumb {
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  width: 1386px;
  margin: 0 auto;
  max-width: 100%;
}
.header-block .header-breadcrumb ul {
  padding: 0;
  color: #FFFFFF;
  font-size: 14px;
  list-style: none;
}
.header-block .header-breadcrumb ul li {
  display: inline-block;
  color: #FFFFFF;
  font-weight: 300;
}
.header-block .header-breadcrumb ul li a {
  color: #FFFFFF;
  text-decoration: underline;
  font-weight: 300;
}
.header-block .header-copyright {
  position: absolute;
  bottom: 5px;
  right: 10px;
  opacity: 0.7;
}

.header-title {
  text-align: center;
}
.header-title h5 {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 900;
  line-height: 45px;
}
.header-title h1 {
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 900;
  line-height: 45px;
}
.header-title h2 {
  color: #FFFFFF;
  font-size: 35px;
  font-weight: 200;
  line-height: 45px;
}

.speech-bubble {
  transition: all 400ms cubic-bezier(0.77, 0, 0.175, 1);
  position: relative;
  padding: 36px 15px;
  border-radius: 999px;
  background: #63ABCC;
}
.speech-bubble:after {
  transition: all 400ms cubic-bezier(0.77, 0, 0.175, 1);
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin-bottom: -15px;
  margin-left: -7.5px;
  border: 15px solid transparent;
  border-right: 0;
  border-bottom: 0;
  border-top-color: #63ABCC;
}
.speech-bubble:hover {
  background: #32529D;
  text-decoration: none;
}
.speech-bubble:hover:after {
  border-top-color: #32529D;
}

.chat-bot .container {
  position: relative;
}
.chat-bot .speech-bubble {
  position: absolute;
  right: 0;
  bottom: 33px;
}

.case-item {
  display: block;
  padding: 30px;
}
.case-item .person {
  width: 65px;
  height: 65px;
  border-radius: 999px;
  background-position: center;
  background-size: cover;
}
.case-item:hover {
  text-decoration: none;
}

.case-block {
  display: block;
  height: 100%;
  background-color: #FFFFFF;
}
@media screen and (min-width: 768px) {
  .case-block h2 {
    font-size: 28px;
    font-weight: 600;
    line-height: 44px;
  }
}
.case-block .btn-case {
  display: flex;
  color: #2F287B;
  text-decoration: underline;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}
.case-block .btn-case img {
  height: 18px;
}
.case-block .btn-case:hover {
  text-decoration: none;
}
.case-block:hover {
  text-decoration: none;
}

.cta-block {
  display: flex;
  padding: 55px 55px;
  background-color: #FFFFFF;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.cta-block h2 {
  font-size: 18px;
}
@media screen and (min-width: 768px) {
  .cta-block h2 {
    font-size: 20px;
  }
}
@media screen and (min-width: 1440px) {
  .cta-block h2 {
    font-size: 24px;
  }
}

.image-block {
  width: 100%;
  padding-top: 66%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.image-block.square {
  padding-top: 100%;
}
.image-block.fill-height {
  height: 100%;
  padding-top: auto;
}

.blog-item {
  width: 100%;
}
.blog-item .image {
  width: 100%;
  height: auto !important;
  padding-top: 50% !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.blog-item .date {
  color: rgba(0, 0, 0, 0.6);
}

.breadcrumbs {
  display: flex;
  position: absolute;
  top: 10px;
  left: 0;
  align-items: center;
  justify-content: flex-start;
}
.breadcrumbs a {
  color: #2F287B;
  text-decoration: underline;
  font-size: 12px;
  font-weight: normal;
}
.breadcrumbs a.active {
  text-decoration: none;
}
.breadcrumbs a:hover {
  color: #63ABCC;
  text-decoration: none;
}
.breadcrumbs span {
  margin-right: 4px;
  margin-left: 4px;
  font-size: 10px;
  font-weight: 100;
}

.popup {
  background-color: rgba(255, 255, 255, 0.5);
}
.popup .modal-dialog {
  margin-top: 10%;
  width: 600px;
  max-width: calc(100% - 50px);
  margin-left: auto;
  margin-right: auto;
}
.popup .modal-dialog .close {
  position: absolute;
  z-index: 3;
  right: -24px;
  top: 0;
  background-color: white;
  /* line-height: 22px; */
  padding: 5px;
}
.popup .modal-dialog .close:focus {
  outline: none;
}
.popup .modal-dialog .close span {
  color: #939598;
}
.popup .modal-dialog .modal-content {
  border-radius: 0;
  border: 0;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
}
.popup .modal-dialog .modal-content .modal-body {
  overflow: hidden;
}
.popup .modal-dialog .modal-content .modal-footer {
  border-top: 0;
}

.newsletter_popup {
  background-color: rgba(255, 255, 255, 0.5);
}
.newsletter_popup .modal-dialog span {
  color: #FFFFFF;
}
.newsletter_popup .modal-dialog .modal-content {
  border-radius: 0;
  border: 0;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #abdbe3;
  color: #2F287B;
  overflow: hidden;
}
.newsletter_popup .modal-dialog .modal-content .bg-img {
  right: 0;
  bottom: 0;
  height: 400px;
}
.newsletter_popup .modal-dialog .modal-content .close {
  z-index: 10;
  border: none;
  outline: none;
}
.newsletter_popup .modal-dialog .modal-content .modal-body {
  z-index: 10;
  overflow: hidden;
}

.pwcmb-widget {
  background: #2f287b;
  color: white;
}
.pwcmb-widget .pwcmb-widget__text p {
  color: white;
  font-size: 14px;
  line-height: 1.5;
}
.pwcmb-widget .pwcmb-widget__row {
  margin-top: 1em;
}
.pwcmb-widget .pwcmb-option-wrapper label span {
  color: white;
}
.pwcmb-widget .pwcmb-widget__button {
  color: white !important;
  margin-right: 40px;
  font-size: 12px;
  height: 45px;
}
.pwcmb-widget .pwcmb-widget__button.btn-arrow img {
  right: -25px;
  height: 45px;
}
.pwcmb-widget .pwcmb-widget__button:hover {
  color: #2f287b !important;
}

.edit__page__button {
  transition: all 400ms cubic-bezier(0.77, 0, 0.175, 1);
  display: flex;
  position: fixed;
  right: 20px;
  bottom: 75px;
  width: 50px;
  height: 50px;
  color: #FFF;
  border-radius: 999px;
  background-color: #55EFC4;
  align-items: center;
  font-size: 18px;
  justify-content: center;
}
.edit__page__button:hover {
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  color: #55EFC4;
  background-color: #FFF;
}

.scroll_button {
  transition: all 400ms cubic-bezier(0.77, 0, 0.175, 1);
  display: flex;
  position: fixed;
  bottom: 28px;
  color: #32529D;
  border-radius: 999px;
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 0px 10px #000000;
}
.scroll_button:hover {
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  color: #FFF;
  background-color: #63ABCC;
}
@media (max-width: 450px) {
  .scroll_button {
    font-size: 28px;
  }
}
@media (max-width: 991.98px) {
  .scroll_button {
    font-size: 30px;
  }
}
@media (min-width: 1024px) {
  .scroll_button {
    font-size: 30px;
  }
}
@media (min-width: 1440px) {
  .scroll_button {
    font-size: 35px;
    width: 30px;
    height: 30px;
  }
}

.wrapper__404 {
  display: block;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("/dist/assets/images/404/404-bg.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.wrapper__404 .inner__404 {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 40px;
  background: linear-gradient(45deg, rgba(52, 135, 199, 0.5) 0%, rgba(23, 179, 176, 0.5) 100%);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.wrapper__404 .inner__404 .title__404 {
  margin-bottom: 10px;
  color: #FFF;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 110px;
  font-weight: 700;
  line-height: 110px;
  letter-spacing: -0.05em;
}
.wrapper__404 .inner__404 .text__404 {
  margin-bottom: 40px;
  color: #FFF;
  text-align: center;
  font-family: "Esteban", serif;
  font-size: 26px;
  line-height: 35px;
}
.wrapper__404 .inner__404 .text__404 a {
  color: #FFF;
  text-decoration: underline;
}
.wrapper__404 .inner__404 .text__404 a:hover {
  color: #55EFC4;
}
.wrapper__404 .inner__404 .button__404 {
  transition: all 400ms cubic-bezier(0.77, 0, 0.175, 1);
  padding: 15px 26px;
  color: #414042;
  border-radius: 999px;
  background-color: #FFF;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  letter-spacing: -0.025em;
}
.wrapper__404 .inner__404 .button__404 i,
.wrapper__404 .inner__404 .button__404 svg {
  margin-right: 10px;
  font-size: 12px;
  transition: margin-right 400ms cubic-bezier(0.77, 0, 0.175, 1);
}
.wrapper__404 .inner__404 .button__404:hover {
  color: #FFF;
  background-color: #55EFC4;
  text-decoration: none;
}
.wrapper__404 .inner__404 .button__404:hover i,
.wrapper__404 .inner__404 .button__404:hover svg {
  margin-right: 17px;
}

@media (max-width: 575.98px) {
  .wrapper__404 .inner__404 {
    padding: 25px;
  }
  .wrapper__404 .inner__404 .title__404 {
    font-size: 74px;
    line-height: 74px;
  }
  .wrapper__404 .inner__404 .text__404 {
    font-size: 20px;
    line-height: 28px;
  }
}
.wrapper__coming-soon {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(45deg, #3487C7 0%, #17B3B0 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
}
.wrapper__coming-soon .inner__coming-soon {
  max-width: 500px;
  padding: 40px;
  border-radius: 6px;
  background-color: #FFF;
  box-shadow: 0 50px 100px rgba(0, 0, 0, 0.1);
}
.wrapper__coming-soon .inner__coming-soon .logo__coming-soon {
  width: 150px;
  height: 150px;
  margin: 0px auto 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.wrapper__coming-soon .inner__coming-soon .title__coming-soon {
  margin-bottom: 15px;
  color: #2D3436;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 38px;
}
.wrapper__coming-soon .inner__coming-soon .text__coming-soon {
  color: rgba(45, 52, 54, 0.33);
  font-family: Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
}
.wrapper__coming-soon .inner__coming-soon .text__coming-soon a {
  color: #17B3B0;
  text-decoration: underline;
}
.wrapper__coming-soon .inner__coming-soon .text__coming-soon a:hover {
  color: #2D3436;
  text-decoration: none;
}
.wrapper__coming-soon .inner__coming-soon .contact__coming-soon {
  margin-top: 35px;
}
.wrapper__coming-soon .inner__coming-soon .contact__coming-soon h2 {
  margin-bottom: 10px;
  color: #2D3436;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
}
.wrapper__coming-soon .inner__coming-soon .socials__coming-soon {
  display: flex;
  margin-top: 35px;
  align-items: center;
  justify-content: space-between;
}
.wrapper__coming-soon .inner__coming-soon .socials__coming-soon h2 {
  color: rgba(45, 52, 54, 0.5);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
}
.wrapper__coming-soon .inner__coming-soon .socials__coming-soon .socials {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.wrapper__coming-soon .inner__coming-soon .socials__coming-soon .socials a {
  margin-right: 10px;
  color: rgba(45, 52, 54, 0.5);
  font-size: 18px;
}
.wrapper__coming-soon .inner__coming-soon .socials__coming-soon .socials a:last-child {
  margin-right: 0;
}
.wrapper__coming-soon .inner__coming-soon .socials__coming-soon .socials a:hover {
  color: #17B3B0;
  text-decoration: none;
}
.wrapper__coming-soon .inner__coming-soon .socials__coming-soon .socials a:hover > svg {
  fill: #2F287B;
}
.wrapper__coming-soon .inner__coming-soon .socials__coming-soon .socials a svg {
  fill: #FFFFFF;
}
.wrapper__coming-soon .inner__coming-soon .socials__coming-soon .socials a svg:hover {
  fill: #2F287B;
}

.FormBuilder form .Inputfield .InputfieldHeader {
  color: #2F287B;
  font-size: 20px;
  font-weight: bold;
}
.FormBuilder form .Inputfield .InputfieldContent input,
.FormBuilder form .Inputfield .InputfieldContent textarea {
  min-height: 49px;
  border-radius: 0;
  box-shadow: none;
  font-family: "allumi-std", sans-serif;
  font-size: 20px;
  font-weight: 100;
  padding: 10px;
}
.FormBuilder form .Inputfield .InputfieldContent input:focus,
.FormBuilder form .Inputfield .InputfieldContent textarea:focus {
  border: 1px solid #63ABCC;
  outline: 0;
}
.FormBuilder form .Inputfield .InputfieldContent input::placeholder,
.FormBuilder form .Inputfield .InputfieldContent textarea::placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.FormBuilder form .Inputfield .InputfieldContent button[type=submit] {
  position: relative;
  padding: 16px 30px 16px;
  padding-top: 12.5px;
  padding-right: 45px;
  padding-bottom: 12.5px;
  color: #FFFFFF;
  background-color: #2F287B;
  text-transform: uppercase;
  font-family: "allumi-std", sans-serif;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0.04em;
}
.FormBuilder form .Inputfield .InputfieldContent button[type=submit] img {
  z-index: 999;
  position: absolute;
  top: -2px;
  right: -40px;
  height: 59px;
}
.FormBuilder form .Inputfield .InputfieldContent button[type=submit]:hover {
  color: #2F287B;
  background-color: #FFFFFF;
}
.FormBuilder form span {
  color: #2F287B;
}
.FormBuilder form span a {
  color: #2F287B;
  text-decoration: underline;
}
.FormBuilder form span a:hover {
  text-decoration: none;
}

.map {
  width: 100%;
  padding-top: 100%;
}

footer {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.35);
}
footer .FormBuilder form .Inputfield .InputfieldHeader {
  color: #2F287B;
  font-size: 18px;
  font-weight: bold;
}
footer .FormBuilder form .Inputfield .InputfieldContent input,
footer .FormBuilder form .Inputfield .InputfieldContent textarea {
  min-height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  font-family: "allumi-std", sans-serif;
  font-size: 18px;
  font-weight: 100;
  padding: 6px 10px;
}
@media (max-width: 768px) and (min-width: 476px) {
  footer .FormBuilder form .Inputfield .InputfieldContent input,
  footer .FormBuilder form .Inputfield .InputfieldContent textarea {
    width: 80%;
  }
}
footer .FormBuilder form .Inputfield .InputfieldContent input:focus,
footer .FormBuilder form .Inputfield .InputfieldContent textarea:focus {
  border: 1px solid #63ABCC;
  outline: 0;
}
footer .FormBuilder form .Inputfield .InputfieldContent input::placeholder,
footer .FormBuilder form .Inputfield .InputfieldContent textarea::placeholder {
  color: rgba(0, 0, 0, 0.4);
}
footer .FormBuilder form .Inputfield .InputfieldContent button[type=submit] {
  position: relative;
  padding: 16px 30px 16px;
  padding-top: 7px;
  padding-right: 30px;
  padding-bottom: 7px;
  color: #FFFFFF;
  background-color: #2F287B;
  text-transform: uppercase;
  font-family: "allumi-std", sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.04em;
}
footer .FormBuilder form .Inputfield .InputfieldContent button[type=submit] img {
  z-index: 999;
  position: absolute;
  top: -2px;
  right: -29px;
  height: 42px;
}
footer .FormBuilder form .Inputfield .InputfieldContent button[type=submit]:hover {
  color: #2F287B;
  background-color: #FFFFFF;
}

.browserupgrade {
  width: 100vw;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #FFF;
  background-color: #eb4d4b;
  text-align: center;
}
.browserupgrade a {
  color: #FFF;
  text-decoration: underline;
}
.browserupgrade a:hover {
  text-decoration: none;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1386px;
  }
}
#FormBuilder_webinar input[type=checkbox] {
  min-height: 0 !important;
}

@media (max-width: 424px) {
  #webinarForm iframe {
    width: 242px !important;
  }
}

#FormBuilder_contactformulier .google-terms,
#FormBuilder_newsletter_popup .google-terms {
  font-size: 65%;
  color: #9ca3af;
}
#FormBuilder_contactformulier .google-terms a,
#FormBuilder_newsletter_popup .google-terms a {
  color: #9ca3af;
  text-decoration: underline;
}
#FormBuilder_contactformulier .google-terms a:hover,
#FormBuilder_newsletter_popup .google-terms a:hover {
  color: #9ca3af;
  text-decoration: none;
}

#FormBuilder_newsletter_popup .Inputfield {
  margin-bottom: 0 !important;
}

#jobs .job-title {
  font-size: 26px;
}
#jobs .quote {
  min-height: 120px;
}

.no-scrolling {
  height: 100%;
  overflow: hidden;
}

.navbar-toggler {
  display: flex;
  color: #63ABCC;
  align-items: center;
  justify-content: flex-start;
}

.off-canvas-nav {
  z-index: 9999 !important;
  display: flex;
  position: fixed;
  top: 0;
  right: -520px;
  bottom: 0;
  width: 520px;
  padding: 40px;
  overflow-y: scroll;
  border-left: 2px solid #F0F0F0;
  background: #FFFFFF;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  transition: right 500ms cubic-bezier(0.77, 0, 0.175, 1);
}
.off-canvas-nav section {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.off-canvas-nav section .burger-menu {
  align-self: flex-end;
}
.off-canvas-nav ul {
  margin: 0;
  padding: 0;
}
.off-canvas-nav ul li {
  margin: 10px 0;
  list-style: none;
}
.off-canvas-nav ul li a {
  color: #2F287B;
  font-size: 16px;
  font-weight: 700;
}
.off-canvas-nav ul li a.active {
  color: #63ABCC;
  font-weight: 700;
}
.off-canvas-nav ul li a:hover {
  text-decoration: none;
  color: #63ABCC;
}
.off-canvas-nav ul li a .svg-inline--fa {
  font-size: 16px;
  padding-top: 2px;
}
.off-canvas-nav ul li a:focus {
  color: #63ABCC;
  font-weight: 700;
}
.off-canvas-nav.active {
  display: flex;
  right: 0;
  box-shadow: -10px 0px 80px 0px rgba(0, 0, 0, 0.25);
}
.off-canvas-nav .dropdown-toggle::after {
  display: none;
}

.fullscreen-overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: black;
  transition: background-color 500ms cubic-bezier(0.77, 0, 0.175, 1);
}
.fullscreen-overlay.active {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.ie-width-fix {
  max-width: 100%;
}

* {
  font-feature-settings: "kern" 1;
  font-family: "allumi-std", sans-serif;
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 48px;
  font-weight: 400;
}
h1.small-title {
  font-size: 40px;
  line-height: 45px;
}

h2 {
  font-size: 35px;
  font-weight: 600;
  line-height: 44px;
}
h2 b {
  font-weight: 700;
}
h2.quote {
  font-family: "Playfair Display", serif;
  font-weight: normal;
  line-height: 47px;
  letter-spacing: 0.7px;
}
h2.small-title {
  font-weight: 100;
}

h3 {
  font-size: 24px;
  font-weight: 900;
  line-height: 31px;
}

h4 {
  color: #FFFFFF;
  font-size: 22px;
  font-weight: normal;
  line-height: 28px;
}

.cms,
p {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}
.cms h2,
p h2 {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 14px;
}
@media (min-width: 768px) {
  .cms h2,
  p h2 {
    font-size: 29px;
    line-height: 44px;
  }
}
.cms a,
p a {
  color: #63ABCC;
  text-decoration: none;
}
.cms a i,
.cms a svg,
p a i,
p a svg {
  font-size: 13px;
}
.cms a:hover,
p a:hover {
  color: #2F287B;
  text-decoration: none;
}
.cms pre,
p pre {
  padding: 4px 10px;
  color: #414042;
  background-color: #F0F0F0;
}
.cms.blue-bold b,
.cms.blue-bold strong,
p.blue-bold b,
p.blue-bold strong {
  color: #2F287B;
}
.cms img.align_left,
p img.align_left {
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
  max-width: 100%;
}
.cms img.align_right,
p img.align_right {
  float: right;
  margin-left: 15px;
  margin-bottom: 15px;
  max-width: 100%;
}
.cms img.align_center,
p img.align_center {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;
  max-width: 100%;
}

.play-icon {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: white;
  background-color: rgba(255, 255, 255, 0.8);
  align-items: center;
  font-size: 64px;
  flex-direction: column;
  justify-content: center;
  transition: font-size 1s;
}
.play-icon:hover {
  font-size: 72px;
}

.titel-link:hover {
  text-decoration: none;
}

.at-resp-share-element .at-share-btn:hover {
  transform: translateY(0px) !important;
}

.at-style-responsive .at-share-btn {
  margin-right: 10px !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

/*.glass {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.7px);
  -webkit-backdrop-filter: blur(3.7px);
  border: 1px solid rgba(255, 255, 255, 0.22);
}*/
.top-bar {
  padding-top: 16px;
  padding-bottom: 36px;
  background-color: #63ABCC;
}
.top-bar .language-buttons a {
  font-size: 16px !important;
}
.top-bar a {
  position: relative;
  margin: 0 20px;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 600;
}
.top-bar a .badge {
  position: relative;
  bottom: 8px;
  width: 14px;
  height: 14px;
  color: #FFFFFF;
  border-radius: 999px;
  background-color: #FF0000;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
}
.top-bar a:hover {
  color: #32529D;
  text-decoration: none;
}
.top-bar a:last-child {
  margin: 0px 0px 0px 20px;
}
.top-bar a.active {
  color: #32529D;
}

.navbar {
  z-index: 1;
  height: 100px;
  margin-top: -20px;
  padding: 0 65px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.navbar .navbar-brand {
  width: 160px;
}
.navbar .navbar-brand img {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .navbar .navbar-brand img {
    width: 80%;
    padding: 10px;
    margin-top: 10px;
  }
}
@media screen and (min-width: 992px) {
  .navbar .navbar-brand img {
    width: 100%;
    padding: 20px;
    margin-top: 0;
  }
}
.navbar .nav-item {
  margin: 0;
  padding: 0;
}
.navbar .nav-item .nav-link {
  margin: 0;
  padding: 0 15px;
  color: #32529D;
  font-size: 18px;
  font-weight: 600;
}
.navbar .nav-item .nav-link .svg-inline--fa {
  font-size: 16px;
  padding-top: 2px;
}
@media screen and (min-width: 1200px) {
  .navbar .nav-item .nav-link {
    font-size: 24px;
    padding: 0 20px;
  }
  .navbar .nav-item .nav-link .svg-inline--fa {
    font-size: 20px;
    padding-top: 1px;
  }
}
.navbar .nav-item .nav-link:hover {
  color: #63ABCC;
}
.navbar .nav-item .dropdown-menu {
  border-radius: 0;
  margin: 0;
  padding-top: 20px;
  border: none;
}
.navbar .nav-item .dropdown-menu .dropdown-item {
  color: #2F287B;
}
.navbar .nav-item .dropdown-menu .dropdown-item:hover {
  color: #63ABCC;
}
.navbar .nav-item .dropdown-menu .dropdown-item.active {
  color: #63ABCC;
}
.navbar .nav-item:last-child .nav-link {
  padding: 0 0 0 20px;
}
.navbar .nav-item:first-child .nav-link {
  padding: 0 20px 0 0;
}
.navbar .nav-item.active .nav-link {
  color: #63ABCC;
}
.navbar .nav-item:hover .dropdown-menu {
  display: block;
}
.navbar .nav-item:hover .svg-inline--fa {
  transform: rotate(180deg);
  transition: 0.5s;
}
.navbar .nav-item .dropdown-toggle::after {
  display: none;
}

footer {
  background-image: url(/dist/assets/images/bg-element.svg);
  background-color: #32529D;
  background-repeat: no-repeat;
  background-position: right -40px center;
  background-size: auto 110%;
}
footer .footer-menu a {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
}
footer .footer-menu a:last-child {
  margin-right: 0;
}
footer .footer-menu a:hover {
  color: #63ABCC;
  text-decoration: none;
}
footer .footer-menu a .badge {
  position: relative;
  bottom: 8px;
  width: 14px;
  height: 14px;
  color: #fff;
  border-radius: 999px;
  background-color: red;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
}
footer .footer-socials {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
footer .footer-socials a {
  display: flex;
  width: 35px;
  height: 35px;
  margin-right: 12px;
  color: #2F287B;
  border-radius: 3px;
  background-color: #FFFFFF;
  align-items: center;
  font-size: 18px;
  justify-content: center;
}
footer .footer-socials a:last-child {
  margin-right: 0;
}
footer .footer-socials a:hover {
  background-color: #63ABCC;
  color: #FFFFFF;
  transition: 0.2s all ease-in-out;
}
footer .footer-socials a:hover > svg {
  fill: #FFFFFF;
}
footer .footer-socials a svg {
  fill: #2F287B;
}
footer .footer-socials a svg:hover {
  fill: #FFFFFF;
}
footer .dx-image {
  height: 100px;
}
footer .google-terms {
  font-size: 65%;
  color: #e5e5e5;
}
footer .google-terms a {
  color: #e5e5e5;
  text-decoration: underline;
}
footer .google-terms a:hover {
  color: #e5e5e5;
  text-decoration: none;
}

.bottom-bar {
  color: #FFFFFF;
}
.bottom-bar a {
  padding: 0 5px;
  color: #FFFFFF;
  font-size: 16px;
}

.logo-bar .footer-image img {
  height: 70px;
  margin: 10px 0;
}

.home__diensten {
  z-index: 1;
  margin-top: -70px;
  padding: 75px 75px 0px 75px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.home__diensten .dienst img {
  margin-bottom: 16px;
}
.home__diensten .dienst p {
  font-size: 15px;
}
.home__diensten .dienst a:hover {
  text-decoration: none;
}
.home__diensten .dienst .subpage-list {
  padding: 0;
  list-style: none;
}
.home__diensten .dienst .subpage-list a {
  color: #63ABCC;
  text-decoration: none;
}
.home__diensten .dienst .subpage-list a:hover {
  color: #2F287B;
}
.home__diensten .dienst .subpage-list a .icon {
  vertical-align: baseline;
}

.case-block {
  position: relative;
}
.case-block .image {
  position: absolute;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.case-block .image.left {
  right: 50%;
  left: 0;
}
.case-block .image.right {
  right: 0;
  left: 50%;
}
.case-block .bg-block {
  position: absolute;
  top: 0;
  bottom: 0;
  background-image: url(../../../dist/assets/images/bg-element.svg);
  background-color: #32529D;
  background-repeat: no-repeat;
  background-position: right -20px bottom -125px;
  background-size: 50% auto;
}
.case-block .bg-block.left {
  right: 50%;
  left: 0;
}
.case-block .bg-block.right {
  right: 0;
  left: 50%;
}
.case-block .bg-block-grey {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #F0F0F0;
  background-repeat: no-repeat;
  background-position: right -20px bottom -125px;
  background-size: 50% auto;
}
.case-block .bg-block-grey.left {
  right: 50%;
  left: 0;
}
.case-block .bg-block-grey.right {
  right: 0;
  left: 50%;
}

.cases-grid img:hover {
  filter: grayscale(0%);
  transition: 0.2s all ease-in-out;
}

.small-text {
  font-size: 1rem;
  font-weight: 300;
}

.references-slider {
  position: relative;
}
@media (max-width: 1470px) {
  .references-slider {
    max-width: calc(100% - 50px);
    margin: 0 auto;
  }
}
@media (max-width: 1420px) {
  .references-slider {
    max-width: calc(100% - 70px);
  }
}
@media (max-width: 1399px) {
  .references-slider {
    width: calc(100% - 26px);
  }
}
@media (max-width: 1018px) {
  .references-slider {
    width: calc(100% - 50px);
  }
}
@media (max-width: 575px) {
  .references-slider {
    width: calc(100% - 70px);
  }
}
.references-slider .slick-slide {
  padding: 0 50px;
  float: none;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.references-slider .slick-slide img {
  vertical-align: middle;
  display: inline-block;
}
.references-slider .slick-slide img:hover {
  filter: grayscale(0%);
  transition: 0.2s all ease-in-out;
}
.references-slider .slider-controls {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -50px;
  left: -50px;
  align-items: center;
  justify-content: space-between;
}
.references-slider .slider-controls a {
  z-index: 999;
}
.references-slider .slider-controls a.prev-slide {
  transform: rotate(180deg);
}

#blog-top .list {
  background-color: #FFFFFF;
  padding-left: 40px;
  height: max-content;
}
@media (min-width: 1440px) {
  #blog-top .list {
    padding-left: 65px;
  }
}
#blog-top .list .list-items {
  list-style-type: none;
  padding: 0;
}

.blog-grid {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.blog-grid .blog-item {
  display: flex;
  padding: 80px 55px;
  border-right: 2px solid rgba(0, 0, 0, 0.8);
  background-color: #FFFFFF;
  align-items: center;
  flex: 0 0 33.33333%;
  flex-direction: column;
  justify-content: center;
}
.blog-grid .blog-item .date {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
}
.blog-grid .blog-item:last-child {
  border-right: 0;
}

.event a {
  text-decoration: none;
}

#service-related-blogs .blog-item {
  border: 1px solid #F0F0F0;
}
#service-related-blogs .blog-item:hover .card-footer-link a {
  color: #2F287B;
  transition: 0.3s ease-in-out;
}

.social-block .social-block-wrapper {
  padding: 0 4px;
}
.social-block .social-block-wrapper span {
  width: 100% !important;
}
.social-block .social-block-wrapper span iframe {
  width: 100% !important;
}
.social-block .social-block-wrapper .blog-item {
  border-bottom: 2px solid rgba(147, 149, 152, 0.8);
  background-color: #FFFFFF;
  flex: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 2px 0;
}
@media (min-width: 1025px) {
  .social-block .social-block-wrapper .blog-item {
    min-height: 175px;
  }
}
@media (min-width: 1440px) {
  .social-block .social-block-wrapper .blog-item {
    min-height: 160px;
  }
}
.social-block .social-block-wrapper .blog-item .date {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}
.social-block .social-block-wrapper .blog-item .event-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
@media (min-width: 1199px) {
  .social-block .social-block-wrapper .blog-item .event-image {
    height: auto;
    object-fit: cover;
  }
}
.social-block .social-block-wrapper .blog-item h3 {
  font-size: 16px;
}
.social-block .social-block-wrapper .blog-item:last-of-type {
  margin-bottom: 0 !important;
  border-bottom: none;
}
.social-block .social-block-wrapper:last-of-type {
  border-bottom: none;
}
@media (max-width: 767px) {
  .social-block .social-block-wrapper {
    padding: 0 15px;
  }
}
.social-block .social-block-wrapper.fb .inner .fb_item {
  background-color: #FFFFFF;
  border-bottom: 2px solid rgba(147, 149, 152, 0.8);
}
.social-block .social-block-wrapper.fb .inner .fb_item .date {
  color: #939598;
}
.social-block .social-block-wrapper.fb .inner .fb_item .cms {
  font-size: 14px;
  line-height: 21px;
  opacity: 0.8;
}
.social-block .social-block-wrapper.fb .inner .fb_item .action-item a {
  font-size: 14px;
  color: #939598;
}
.social-block .social-block-wrapper.fb .inner .fb_item .action-item a:hover {
  text-decoration: none;
  color: #32529D;
}
.social-block .social-block-wrapper.fb .inner .fb_item a.well-link .well {
  background-color: rgba(240, 240, 240, 0.01);
  font-size: 12px;
}
.social-block .social-block-wrapper.fb .inner .fb_item a.well-link .well b {
  text-decoration: underline;
}
.social-block .social-block-wrapper.fb .inner .fb_item a.well-link .well:hover {
  text-decoration: none;
}
.social-block .social-block-wrapper.fb .inner .fb_item a.well-link:hover {
  text-decoration: none;
}

.map {
  width: 100%;
  padding-top: 75%;
  background-color: #F0F0F0;
}

.arrow-text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.arrow-text img {
  margin-right: 25px;
}
.arrow-text ul li {
  margin-bottom: 16px;
}
.arrow-text ul li:last-child {
  margin-bottom: 0;
}

.case-cta {
  display: flex;
  background-color: #FFFFFF;
  justify-content: space-between;
  flex-wrap: wrap;
}
.case-cta .left .inner,
.case-cta .right .inner {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 90px 100px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.case-cta .left {
  border-right: 2px solid rgba(0, 0, 0, 0.8);
  flex: 0 0 66.666%;
  max-width: 66.6%;
}
.case-cta .right {
  flex: 0 0 33.333%;
  max-width: 33.3%;
}
.case-cta .center-share {
  padding: 90px 50px;
  max-width: 960px;
}
.case-cta .center-share a.a2a_button_facebook {
  background-color: #3e5b98;
}
.case-cta .center-share a.a2a_button_twitter {
  background-color: #4da7de;
}
.case-cta .center-share a.a2a_button_linkedin {
  background-color: #3371b7;
}
.case-cta .center-share a.a2a_button_email {
  background-color: #07002d;
}
.case-cta .center-share a.share-link {
  text-align: left;
}
.case-cta .center-share a.share-link .icon {
  font-size: 24px;
}
.case-cta .center-share a.share-link span {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
}

.cases-slider .slick-slide {
  height: 100%;
}
.cases-slider .slick-slide div {
  height: 100%;
}
.cases-slider .slick-slide .slide-wrapper {
  height: 100% !important;
}
.cases-slider .slick-slide .case-block {
  height: 100% !important;
}
.cases-slider .slick-dots {
  position: absolute;
  bottom: 35px;
}
.cases-slider .slick-dots li button::before {
  color: #63ABCC;
  opacity: 1;
  font-size: 28px;
}
.cases-slider .slick-dots li.slick-active button::before {
  color: #FFFFFF;
}

.top-case-card {
  transition: 0.3s all ease-in-out;
}
.top-case-card:hover {
  transform: scale(1.05);
}
.top-case-card:hover .cms a {
  color: #2f287b;
  text-decoration: none;
}

.case-body p, .case-body ul, .case-body li {
  color: #d4d4d4;
  font-size: 18px;
}
.case-body h2, .case-body h3, .case-body h4 {
  color: #d4d4d4;
}
.case-body p a:hover {
  color: #FFFFFF;
}

@media (max-width: 991px) {
  .case-logo-image {
    width: 50%;
  }
}

.case-arrow {
  height: 10%;
  bottom: -10px;
  right: 5px;
}
@media (max-width: 425px) and (min-width: 376px) {
  .case-arrow {
    height: 15%;
  }
}
@media (max-width: 767px) and (min-width: 426px) {
  .case-arrow {
    height: 20%;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .case-arrow {
    height: 35%;
  }
}
@media (min-width: 992px) {
  .case-arrow {
    height: 50%;
    bottom: -30px;
  }
}

.case-image {
  background-position: left center;
  background-size: cover;
}

.quote-image {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (min-width: 1200px) {
  .quote-image {
    position: absolute;
  }
}

.case-person-box .case-person-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-position: 40% 50%;
  background-size: cover;
  border: 1px solid #DCDCFF;
}
.case-person-box .case-person-text span {
  font-weight: lighter;
}

.quote-card p {
  font-size: 16px;
}

.border-blue {
  border: #2F287B;
}

.services-grid-blue {
  display: flex;
  margin-top: -127.5px;
  justify-content: center;
  flex-wrap: wrap;
}
.services-grid-blue .service-blue-item {
  transition: all 400ms cubic-bezier(0.77, 0, 0.175, 1);
  display: block;
  position: relative;
  background-color: #63ABCC;
  flex: 1 0 auto;
  font-size: 1rem;
  text-decoration: none;
}
.services-grid-blue .service-blue-item p {
  color: #FFFFFF;
}
@media (max-width: 600px) {
  .services-grid-blue .service-blue-item {
    max-width: 80%;
  }
}
@media only screen and (max-width: 900px) and (min-width: 600px) {
  .services-grid-blue .service-blue-item {
    max-width: 40%;
  }
}
.services-grid-blue .service-blue-item .service-icon {
  max-height: 50%;
  margin-bottom: 25px;
}
.services-grid-blue .service-blue-item:hover {
  background-color: #32529D;
  text-underline-style: none;
}

.services-grid {
  display: flex;
  margin-top: -127.5px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.services-grid .service-item {
  transition: all 400ms cubic-bezier(0.77, 0, 0.175, 1);
  display: block;
  position: relative;
  margin-right: 20px;
  margin-bottom: 22px;
  background-color: #63ABCC;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  flex: 1 0 auto;
  max-width: 16%;
  width: 100%;
}
@media (max-width: 1200px) {
  .services-grid .service-item {
    max-width: 18%;
  }
}
.services-grid .service-item .inner {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 25px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.services-grid .service-item .inner .service-icon {
  max-height: 50%;
  margin-bottom: 25px;
}
@media (max-width: 1200px) {
  .services-grid .service-item .inner .service-icon {
    margin-bottom: 0;
  }
}
.services-grid .service-item .inner .text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.services-grid .service-item .inner .text h5 {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: normal;
}
.services-grid .service-item:before {
  content: "";
  width: 100%;
  float: left;
  padding-top: 100%;
}
.services-grid .service-item:hover {
  background-color: #32529D;
}
.services-grid.white {
  margin-top: 0;
}
.services-grid.white .service-item {
  background-color: #FFFFFF;
}
.services-grid.white .service-item:hover {
  transform: scale(1.1);
}
.services-grid.white .service-item .inner .text h5 {
  font-size: 18px;
  color: #000000;
}

.service-item-hover:hover {
  background-color: #2F287B;
  color: #FFFFFF;
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;
}

.voordelen-block {
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 120px;
  padding-right: 110px;
  padding-bottom: 90px;
  padding-left: 110px;
  background-color: #63ABCC;
  align-items: center;
  flex-direction: column;
}
.voordelen-block .wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.voordelen-block .wrapper .voordeel-item {
  display: flex;
  align-items: center;
  flex: 0 0 27.5%;
  flex-direction: column;
  justify-content: flex-end;
}
.voordelen-block .button-wrapper {
  position: absolute;
  right: 0;
  bottom: -5px;
  left: 0;
}
@media (min-width: 768px) {
  .voordelen-block .button-wrapper {
    position: absolute;
    right: 0;
    bottom: -30px;
    left: 0;
  }
}

.image-fit {
  object-fit: cover;
  max-height: 400px;
  width: 100%;
}

.about-grid-bg {
  background-color: rgba(0, 0, 0, 0.8);
}
.about-grid-bg .about-grid {
  display: flex;
  width: 100%;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
}
.about-grid-bg .about-grid .about-item {
  margin-right: 1px;
  margin-bottom: 1px;
  background-color: #FFFFFF;
  flex-basis: calc(33.333% - 1.333px);
}
.about-grid-bg .about-grid .about-item .inner {
  padding: 50px 60px;
}
.about-grid-bg .about-grid .about-item:nth-child(3n) {
  margin-right: 0;
  flex: 0 0 33.333%;
}
.about-grid-bg .about-grid .about-item:nth-child(4), .about-grid-bg .about-grid .about-item:nth-child(5), .about-grid-bg .about-grid .about-item:nth-child(6) {
  margin-bottom: 0;
}

.team-member-image {
  width: 100%;
  padding-top: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-size: auto 100%;
}

.adviesraad-slider {
  position: relative;
}
.adviesraad-slider .slick-slide {
  margin-left: 18px;
}
.adviesraad-slider .slick-list {
  margin-left: -18px;
}
.adviesraad-slider .slick-track {
  display: flex;
}
.adviesraad-slider .slick-track .slick-slide {
  display: flex;
  height: auto;
}
.adviesraad-slider .slider-controls {
  display: flex;
  position: absolute;
  top: calc(50% - 21px);
  right: -11px;
  left: -11px;
  align-items: center;
  justify-content: space-between;
}
.adviesraad-slider .slider-controls a {
  z-index: 999;
}
.adviesraad-slider .slider-controls a.prev-slide {
  transform: rotate(180deg);
}
.adviesraad-slider .slide-wrapper {
  height: 100%;
  padding-top: 125px;
  padding-bottom: 25px;
}
.adviesraad-slider .slide-wrapper .adviesraad-block {
  display: flex;
  height: 100%;
  padding-right: 90px;
  padding-bottom: 60px;
  padding-left: 90px;
  background-color: #FFFFFF;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.adviesraad-slider .slide-wrapper .adviesraad-block .avatar {
  width: 175px;
  height: 175px;
  margin: 0;
  margin-top: -87px;
  margin-bottom: 60px;
  overflow: hidden;
  align-self: center;
  transform: rotate(45deg);
}
.adviesraad-slider .slide-wrapper .adviesraad-block .avatar .avatar-inner {
  position: absolute;
  top: -30px;
  left: 2px;
  width: 275px;
  height: 275px;
  margin: 0 0 0 -49px;
  background-size: cover;
  transform: rotate(-45deg);
}
.adviesraad-slider .slick-dots li {
  width: 6px;
  height: 6px;
  margin: 0px 4px;
}
.adviesraad-slider .slick-dots li button {
  width: 6px;
  height: 6px;
  background-color: #939598;
  transform: rotate(45deg);
}
.adviesraad-slider .slick-dots li button::before {
  content: "";
}
.adviesraad-slider .slick-dots li.slick-active button {
  background-color: #2F287B;
}

.blog-detail {
  width: 100%;
}
.blog-detail .image {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.blog-detail .date {
  color: rgba(0, 0, 0, 0.6);
}

.blog-image {
  min-width: 100%;
}

.infinite-hide-card {
  height: auto;
  width: 100%;
  display: none;
}

.not-found h2,
.not-found h3 {
  color: #32529D !important;
}

.lost-image {
  height: 800px;
  object-fit: cover;
}

.diensten {
  padding: 30px 10px 0px 10px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
@media (min-width: 425px) {
  .diensten {
    padding: 55px 35px 0px 35px;
  }
}
@media (min-width: 768px) {
  .diensten {
    padding: 65px 65px 0px 65px;
  }
}
.diensten .dienst img {
  margin-bottom: 16px;
}
.diensten .dienst a:hover {
  text-decoration: none;
}
.diensten .dienst .subpage-list {
  padding: 0;
  list-style: none;
}
.diensten .dienst .subpage-list a {
  color: #63ABCC;
  text-decoration: none;
}
.diensten .dienst .subpage-list a:hover {
  color: #2F287B;
}
.diensten .dienst .subpage-list a .icon {
  vertical-align: baseline;
}

.socials {
  display: flex;
  align-items: center;
}
.socials a {
  display: flex;
  width: 35px;
  height: 35px;
  margin-right: 12px;
  color: #FFFFFF;
  border-radius: 3px;
  background-color: #63ABCC;
  align-items: center;
  font-size: 18px;
  justify-content: center;
}
.socials a:last-child {
  margin-right: 0;
}
.socials a:hover {
  background-color: #FFFFFF;
  border: 1px solid #63ABCC;
  color: #2F287B;
  transition: 0.2s all ease-in-out;
}
.socials a:hover > svg {
  fill: #2F287B;
}
.socials a svg {
  fill: #FFFFFF;
}
.socials a svg:hover {
  fill: #2F287B;
}

.modal_language p {
  font-size: 1rem;
}
.modal_language .top-img-modal {
  height: 250px;
  object-fit: cover;
}
.modal_language .top-container {
  z-index: 10;
  margin-top: 80px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.8;
}
.modal_language .mid-container {
  z-index: 10;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  opacity: 0.8;
}
.modal_language .modal-image {
  width: 60%;
}
.modal_language .last-container li {
  list-style: none;
}
.modal_language .last-container a {
  color: black !important;
  text-decoration: none;
}
.modal_language .footer-socials {
  display: flex;
  align-items: center;
}
.modal_language .footer-socials a {
  display: flex;
  width: 35px;
  height: 35px;
  margin-right: 12px;
  color: #FFFFFF !important;
  border-radius: 3px;
  background-color: black;
  align-items: center;
  font-size: 18px;
  justify-content: center;
}
.modal_language .footer-socials a:last-child {
  margin-right: 0;
}
.modal_language .footer-socials a:hover {
  background-color: #FFFFFF;
  color: black !important;
  transition: 0.2s all ease-in-out;
}
.modal_language .footer-socials a:hover > svg {
  fill: black;
}
.modal_language .footer-socials a svg {
  fill: #FFFFFF;
}
.modal_language .footer-socials a svg:hover {
  fill: black;
}

.img-sm {
  max-width: 250px;
}

.img-md {
  max-width: 350px;
}

@media (min-width: 768px) {
  .img-lg {
    max-width: 450px;
  }
  .img-xl {
    max-width: 550px;
  }
}
#intro {
  background-color: #F0F0F0;
}
#intro .list {
  background-color: #FFFFFF;
  padding-left: 40px;
  height: max-content;
}
@media (min-width: 1440px) {
  #intro .list {
    padding-left: 65px;
  }
}
#intro .list .list-items {
  list-style-type: none;
  padding: 0;
}
@media (max-width: 767px) {
  #intro .cta-button .btn {
    width: 75% !important;
  }
}
#intro .cta-button a {
  color: #FFFFFF;
  text-decoration: none;
}
#intro .cta-button a:hover {
  color: #2F287B;
}

#share-socials {
  background-color: #63ABCC;
}

.category-link:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  .technology-image {
    width: 100%;
  }
}
.pim-extern-content a {
  color: white;
}
.pim-extern-content a:hover {
  color: #2F287B;
}

.pim-links a {
  color: #000000 !important;
}

.function-item {
  transition: all 0.2s ease-in-out;
}
.function-item a {
  text-decoration: none;
  color: black;
}
.function-item:hover {
  transform: scale(1.1);
}

.image-landingpage {
  object-fit: cover;
  width: 100%;
  height: auto;
}
@media (min-width: 992px) {
  .image-landingpage {
    height: 500px;
    object-position: center;
  }
}

.iframe-box {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.event-content .event-title {
  font-size: 36px;
}

@media (max-width: 575.98px) {
  .off-canvas-nav {
    right: -100vw;
    width: 100vw;
  }
  .btn-mobile {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    height: 48px !important;
    padding-right: 10px !important;
    padding-left: 6px !important;
    align-items: center;
    font-size: 11px !important;
    justify-content: space-between;
  }
  .event-back-wrapper {
    width: 100%;
  }
  .event-back-wrapper .event-back {
    width: 100%;
    text-align: center;
  }
  .header-block.header-services {
    min-height: 0;
  }
  .header-block .services-subtitle {
    font-size: 25px;
    width: 80%;
    margin-top: 10px;
    line-height: 35px;
  }
  .case-block {
    padding: 0;
  }
  .btn-arrow--secondary-border {
    font-size: 14px !important;
    width: 280px;
    padding-bottom: 18px;
  }
}
@media (max-width: 1199.98px) {
  .case-cta .left .inner,
  .case-cta .right .inner {
    padding: 75px 35px;
  }
}
@media (max-width: 767.98px) {
  body {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .navbar {
    height: 80px;
    margin-top: 0;
    box-shadow: none;
  }
  .navbar .navbar-brand {
    width: 100px;
  }
  .header-block {
    min-height: 210px;
    margin-top: 0;
  }
  h1 {
    font-size: 22px !important;
  }
  h2 {
    font-size: 22px;
    line-height: 28px;
  }
  h2.quote {
    line-height: 30px;
  }
  .home__diensten {
    margin-top: 0;
    padding: 20px;
    box-shadow: none;
  }
  .btn-arrow {
    padding: 10px 35px 10px 16px !important;
    font-size: 16px !important;
  }
  .btn-arrow img {
    right: -33px !important;
    height: 48px !important;
  }
  .bg-element {
    background-size: auto 33%;
  }
  .bg-element.bottom-left {
    background-position: right -50px center;
  }
  .blog-grid {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .blog-grid .blog-item {
    padding: 32px 24px !important;
    border-top: 2px solid rgba(0, 0, 0, 0.8);
    border-right: 0;
    align-items: flex-start;
  }
  .blog-grid .blog-item .cms,
  .blog-grid .blog-item h1,
  .blog-grid .blog-item h2,
  .blog-grid .blog-item h3,
  .blog-grid .blog-item h4,
  .blog-grid .blog-item h5,
  .blog-grid .blog-item h6,
  .blog-grid .blog-item p {
    text-align: left !important;
  }
  .blog-grid .blog-item:first-child {
    border-top: 0;
  }
  .map {
    padding-top: 50% !important;
  }
  .case-cta .left .inner,
  .case-cta .right .inner {
    padding: 50px 40px;
  }
  .cta-block {
    padding: 50px 40px !important;
  }
  .chat-bot .speech-bubble {
    right: 16px;
    bottom: -60px;
  }
  .services-grid {
    margin-top: 32px !important;
  }
  .services-grid .service-item {
    padding-top: 50% !important;
    flex: 0 0 100% !important;
  }
  .about-grid-bg .about-grid .about-item {
    margin-right: 0 !important;
    flex: 0 0 100% !important;
  }
  .about-grid-bg .about-grid .about-item .inner {
    padding: 35px 45px !important;
  }
  .about-grid-bg .about-grid .about-item:last-child {
    margin-bottom: 0 !important;
  }
  .adviesraad-slider {
    position: relative;
  }
  .adviesraad-slider .slick-slide {
    margin-left: 18px;
  }
  .adviesraad-slider .slick-list {
    margin-left: -18px;
  }
  .adviesraad-slider .slider-controls {
    display: flex;
    position: absolute;
    top: calc(50% - 21px);
    right: -11px;
    left: -11px;
    align-items: center;
    justify-content: space-between;
  }
  .adviesraad-slider .slider-controls a {
    z-index: 999;
  }
  .adviesraad-slider .slider-controls a.prev-slide {
    transform: rotate(180deg);
  }
  .adviesraad-slider .slide-wrapper .adviesraad-block {
    padding-right: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
  }
  .adviesraad-slider .slide-wrapper .adviesraad-block .cms {
    font-size: 15px;
    line-height: 25px;
  }
  .voordelen-block .wrapper {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .voordelen-block .wrapper .voordeel-item {
    margin-bottom: 40px;
  }
  .voordelen-block .wrapper .voordeel-item:last-child {
    margin-bottom: 0;
  }
  .voordelen-block .btn {
    bottom: -20px;
    margin-left: -8px;
    transform: scale(0.85);
  }
  .btn-scale-mobile {
    margin-left: -8px;
    transform: scale(0.85);
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
  .navbar {
    padding: 0 16px;
  }
  h1 {
    font-size: 38px;
  }
  .case-block {
    position: relative;
  }
  .case-block .image {
    display: block;
    position: relative;
    width: 100%;
    height: 400px;
  }
  .case-block .image.left {
    right: auto;
    left: auto;
  }
  .case-block .image.right {
    right: auto;
    left: auto;
  }
  .case-block .bg-block {
    display: none;
  }
  .case-block .container {
    max-width: 100%;
    margin: 0;
    padding-right: 30px;
    padding-left: 30px;
    background-color: #32529D;
  }
  .references-grid .reference-item {
    max-width: 33%;
    height: 170px;
    border: 0;
    flex: 0 0 33.333%;
  }
  .person-img {
    position: relative;
    bottom: auto;
    margin-bottom: calc(0 - 3rem);
  }
  .jobs-img {
    position: relative;
    bottom: auto;
    margin-bottom: calc(0 - 1.5rem);
  }
  .blog-grid {
    flex-wrap: wrap;
  }
  .blog-grid .blog-item {
    padding: 60px 30px;
    flex: 1 0 50%;
  }
  .blog-grid .blog-item .date {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
  }
  .blog-grid .blog-item:last-child {
    border-top: 2px solid rgba(0, 0, 0, 0.8);
    border-right: 0;
  }
  .blog-grid .blog-item:nth-child(2) {
    border-right: 0;
  }
  .header-block h4 {
    font-size: 18px;
    line-height: 26px;
  }
  .speech-bubble {
    position: absolute;
    right: 0;
    bottom: -95px;
  }
  footer .footer-menu {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  footer .footer-socials {
    justify-content: flex-start;
  }
  .map {
    padding-top: 33%;
  }
  .case-cta .left {
    border-right: 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.8);
  }
  .cta-block {
    padding: 60px;
  }
  .about-grid-bg .about-grid .about-item {
    flex-basis: calc(50% - 1.333px);
  }
  .about-grid-bg .about-grid .about-item .inner {
    padding: 35px 45px;
  }
  .services-grid-blue {
    margin-top: 35px;
  }
  .services-grid {
    display: flex;
    margin-top: 35px;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .services-grid .service-item {
    margin-bottom: 20px;
    padding-top: 30%;
    flex-basis: calc(50% - 20px);
    flex-grow: 0;
    flex-shrink: 1;
    max-width: 100% !important;
  }
  .services-grid .service-item:last-child {
    margin-right: 20px;
  }
  .services-grid .service-item:before {
    width: calc(50% - 20px);
    padding-top: calc(50% - 20px);
  }
  .voordelen-block {
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 70px;
    padding-left: 40px;
  }
  .voordelen-block .wrapper .voordeel-item {
    flex: 0 0 30%;
  }
  .case-cta .left,
  .case-cta .right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 1400px) {
  .services-grid .service-item .resp-fix {
    padding: 20px;
  }
  .services-grid .service-item .resp-fix .text {
    max-width: 100%;
  }
  .services-grid .service-item .resp-fix .text h5 {
    font-size: 18px;
    letter-spacing: 0;
  }
  .services-grid .service-item .resp-fix .text img {
    height: 15px;
  }
}
@media (min-width: 768px) {
  .header-block .header-breadcrumb {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .header-block .header-breadcrumb {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .header-block .header-breadcrumb {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .header-block .header-breadcrumb {
    max-width: 1386px;
  }
}
@media (max-width: 767px) {
  .header-block .header-breadcrumb {
    top: 15px;
    max-width: 100%;
    padding: 0 15px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1440px) {
  .services-grid .service-item {
    max-width: 15%;
  }
}
@media (min-width: 1400px) {
  .modal-xl {
    max-width: 1340px;
  }
}
/* Btn service */
/* Small devices */
.btn-service {
  position: relative;
  padding: 16px 16px;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 12px;
  color: #2f287b;
  border-top: 2px solid #2F287B;
  border-bottom: 2px solid #2F287B;
  border-left: 2px solid #2F287B;
}
.btn-service img {
  z-index: 5;
  position: absolute;
  top: -2px;
  right: -40px;
  height: 54px;
}
.btn-service:hover {
  color: #2F287B;
  background-color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

/* Btn service */
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .btn-service {
    font-size: 15px;
  }
  .btn-service img {
    height: 59px;
  }
}
/* Btn service */
/* Medium devices (tablets, 768px and up) */
@media (min-width: 992px) {
  .btn-service img {
    height: 58px;
  }
}
/* Btn service */
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .btn-service {
    padding: 16px 30px;
    font-size: 18px;
  }
  .btn-service img {
    height: 63px;
  }
}
/* Btn service detail */
/* Small devices */
.btn-service-detail {
  position: relative;
  padding: 5px;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 10px;
  color: #2f287b !important;
  border-top: 2px solid #2F287B;
  border-bottom: 2px solid #2F287B;
  border-left: 2px solid #2F287B;
}
.btn-service-detail img {
  z-index: 5;
  position: absolute;
  top: -2px;
  right: -40px;
  height: 42px;
}
.btn-service-detail:hover {
  color: #FFFFFF !important;
  background-color: #2F287B;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

/* Btn service detail */
/* Small devices (425px and up) */
@media (min-width: 425px) {
  .btn-service-detail {
    font-size: 12px;
  }
}
/* Btn service detail */
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .btn-service-detail {
    font-size: 15px;
  }
}
/* Btn service detail */
/* Extra large devices (large desktops, 1440px and up) */
@media (min-width: 1440px) {
  .btn-service-detail {
    padding: 16px 30px 16px;
    font-size: 16px;
  }
  .btn-service-detail img {
    height: 64px !important;
  }
}
/* Btn case */
/* Small devices */
.btn-case {
  position: relative;
  padding: 16px 16px;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 12px;
  color: #2f287b;
  border-top: 2px solid #2F287B;
  border-bottom: 2px solid #2F287B;
  border-left: 2px solid #2F287B;
}
.btn-case img {
  z-index: 5;
  position: absolute;
  top: -2px;
  right: -36px;
  height: 50px;
}
.btn-case:hover {
  color: #FFFFFF !important;
  background-color: #2F287B;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

/* Btn service */
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .btn-case {
    font-size: 15px;
  }
  .btn-case img {
    height: 52px;
  }
}
/* Btn service */
/* Medium devices (tablets, 768px and up) */
@media (min-width: 992px) {
  .btn-case img {
    height: 52px;
  }
}
/* Btn service */
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .btn-case {
    padding: 16px 30px 16px 20px;
    font-size: 16px;
  }
  .btn-case img {
    height: 54px;
  }
}
$primary_color   : #17B3B0;
$secondary_color : #3487C7;
$splash_color    : #55EFC4;
$text_color      : #2D3436;
$title_font      : 'Montserrat', sans-serif;
$body_font       : 'Esteban', serif;

// ==========[ EDIT BUTTON ]==========

.edit__page__button {
  @include           quart-transition(400ms);
  display          : flex;
  position         : fixed;
  right            : 20px;
  bottom           : 75px;
  width            : 50px;
  height           : 50px;
  color            : #FFF;
  border-radius    : 999px;
  background-color : $splash_color;
  align-items      : center;
  font-size        : 18px;
  justify-content  : center;

  &:hover {
    @include           softshadow;
    color            : $splash_color;
    background-color : #FFF;
  }
}
// ==========[ SCROLL BUTTONS ]==========
.scroll_button{
  @include           quart-transition(400ms);
  display          : flex;
  position         : fixed;
  bottom           : 28px;
  color            : $blue;
  border-radius    : 999px;
  width            : 25px;
  height           : 25px;
  background-color : #ffffff;
  align-items      : center;
  justify-content  : center;
  cursor           : pointer;
  box-shadow       : 0px 0px 10px #000000;
  &:hover {
    @include           softshadow;
    color            : #FFF;
    background-color : $blue-light;
  }
  @media (max-width : 450px)  {
    font-size        : 28px;
  }
  @media (max-width : 991.98px)  {
    font-size        : 30px;
  }
  @media (min-width : 1024px)  {
    font-size        : 30px;
  }
  @media (min-width : 1440px)  {
    font-size        : 35px;
    width            : 30px;
    height           : 30px;
  }

}


// ==========[ 404 PAGE ]==========

.wrapper__404 {
  display             : block;
  position            : relative;
  width               : 100vw;
  height              : 100vh;
  background-image    : url('/dist/assets/images/404/404-bg.gif');
  background-repeat   : no-repeat;
  background-position : center;
  background-size     : cover;

  .inner__404 {
    display         : flex;
    position        : absolute;
    top             : 0;
    right           : 0;
    bottom          : 0;
    left            : 0;
    padding         : 40px;
    background      : linear-gradient(
                        45deg,
                    rgba($secondary_color, .5) 0%,
                    rgba($primary_color, .5) 100%
                      );
    align-items     : center;
    flex-direction  : column;
    justify-content : center;

    .title__404 {
      margin-bottom  : 10px;
      color          : #FFF;
      text-transform : uppercase;
      font-family    : $title_font;
      font-size      : 110px;
      font-weight    : 700;
      line-height    : 110px;
      letter-spacing : -.05em;
    }

    .text__404 {
      margin-bottom : 40px;
      color         : #FFF;
      text-align    : center;
      font-family   : $body_font;
      font-size     : 26px;
      line-height   : 35px;

      a {
        color           : #FFF;
        text-decoration : underline;

        &:hover {
          color : $splash_color;
        }
      }
    }

    .button__404 {
      @include           quart-transition(400ms);
      padding          : 15px 26px;
      color            : $grey-dark;
      border-radius    : 999px;
      background-color : #FFF;
      font-family      : $title_font;
      font-size        : 15px;
      letter-spacing   : -.025em;

      i,
      svg {
        margin-right : 10px;
        font-size    : 12px;
        transition   : margin-right 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
      }

      &:hover {
        color            : #FFF;
        background-color : $splash_color;
        text-decoration  : none;

        i,
        svg {
          margin-right : 17px;
        }
      }
    }
  }
}

@media
  (max-width : 575.98px) {
  .wrapper__404 {
    .inner__404 {
      padding : 25px;

      .title__404 {
        font-size   : 74px;
        line-height : 74px;
      }

      .text__404 {
        font-size   : 20px;
        line-height : 28px;
      }
    }
  }
}

// ==========[ COMING SOON PAGE ]==========

.wrapper__coming-soon {
  display             : flex;
  width               : 100vw;
  height              : 100vh;
  background          : linear-gradient(
                          45deg,
                          $secondary_color 0%,
                          $primary_color 100%
                        );
  background-repeat   : no-repeat;
  background-position : center;
  background-size     : cover;
  align-items         : center;
  justify-content     : center;

  .inner__coming-soon {
    max-width        : 500px;
    padding          : 40px;
    border-radius    : 6px;
    background-color : #FFF;
    box-shadow       : 0 50px 100px rgba(0, 0, 0, .1);

    .logo__coming-soon {
      width               : 150px;
      height              : 150px;
      margin              : 0px auto 20px;
      background-repeat   : no-repeat;
      background-position : center;
      background-size     : cover;
    }

    .title__coming-soon {
      margin-bottom : 15px;
      color         : $text_color;
      text-align    : center;
      font-family   : $title_font;
      font-size     : 24px;
      font-weight   : bold;
      line-height   : 38px;
    }

    .text__coming-soon {
      color       : rgba($text_color, 33%);
      font-family : Helvetica,
                    sans-serif;
      font-size   : 15px;
      font-weight : 500;
      line-height : 25px;

      a {
        color           : $primary_color;
        text-decoration : underline;

        &:hover {
          color           : $text_color;
          text-decoration : none;
        }
      }
    }

    .contact__coming-soon {
      margin-top : 35px;

      h2 {
        margin-bottom : 10px;
        color         : $text_color;
        font-family   : $title_font;
        font-size     : 18px;
        font-weight   : bold;
      }
    }

    .socials__coming-soon {
      display         : flex;
      margin-top      : 35px;
      align-items     : center;
      justify-content : space-between;

      h2 {
        color       : rgba($text_color, 50%);
        font-family : $title_font;
        font-size   : 18px;
        font-weight : bold;
      }

      .socials {
        display         : flex;
        align-items     : center;
        justify-content : flex-end;

        a {
          margin-right : 10px;
          color        : rgba($text_color, 50%);
          font-size    : 18px;

          &:last-child {
            margin-right : 0;
          }

          &:hover {
            color           : $primary_color;
            text-decoration : none;

            > svg {
              fill: $blue-dark;
            }
          }

          svg {
            fill: $white;
          }

          svg:hover {
            fill: $blue-dark;
          }
        }
      }
    }
  }
}

// ==========[ DEFAULT CONTACT FORM ]==========

.FormBuilder {
  form {
    .Inputfield {
      .InputfieldHeader {
        color       : $blue-dark;
        font-size   : 20px;
        font-weight : bold;
      }

      .InputfieldContent {
        input,
        textarea {
          min-height    : 49px;
          border-radius : 0;
          box-shadow    : none;
          font-family   : $ff-allumi-std;
          font-size     : 20px;
          font-weight   : 100;
          padding: 10px;

          &:focus {
            border  : 1px solid $blue-light;
            outline : 0;
          }

          &::placeholder {
            color : rgba(0, 0, 0, 0.4);
          }
        }

        button[type="submit"] {
          position         : relative;
          padding          : 16px 30px 16px;
          padding-top      : 12.5px;
          padding-right    : 45px;
          padding-bottom   : 12.5px;
          color            : $white;
          background-color : $blue-dark;
          text-transform   : uppercase;
          font-family      : $ff-allumi-std;
          font-size        : 20px;
          font-weight      : normal;
          letter-spacing   : 0.04em;

          img {
            z-index  : 999;
            position : absolute;
            top      : -2px;
            right    : -40px;
            height   : 59px;
          }

          &:hover {
            color            : $blue-dark;
            background-color : $white;
          }
        }
      }
    }

    span {
      color: $blue-dark;
      a {
        color: $blue-dark;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.map {
  width       : 100%;
  padding-top : 100%;
}

// ==========[ NEWSLETTER FORM ]==========

footer {
  box-shadow       : 0 3px 6px rgba(0, 0, 0, 0.35);
  .FormBuilder {
    form {
      .Inputfield {
        .InputfieldHeader {
          color: $blue-dark;
          font-size: 18px;
          font-weight: bold;
        }

        .InputfieldContent {
          input,
          textarea {
            min-height: 40px;
            border: 1px solid rgba($black, .8);
            border-radius: 0;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.20);
            font-family: $ff-allumi-std;
            font-size: 18px;
            font-weight: 100;
            padding: 6px 10px;

            @media (max-width: 768px) and (min-width: 476px) {
              width: 80%;
            }

            &:focus {
              border: 1px solid $blue-light;
              outline: 0;
            }

            &::placeholder {
              color: rgba(0, 0, 0, 0.4);
            }
          }

          button[type="submit"] {
            position: relative;
            padding: 16px 30px 16px;
            padding-top: 7px;
            padding-right: 30px;
            padding-bottom: 7px;
            color: $white;
            background-color: $blue-dark;
            text-transform: uppercase;
            font-family: $ff-allumi-std;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0.04em;

            img {
              z-index: 999;
              position: absolute;
              top: -2px;
              right: -29px;
              height: 42px;
            }

            &:hover {
              color: $blue-dark;
              background-color: $white;
            }
          }
        }
      }
    }
  }
}

// ==========[ BROWSERUPGRADE ]==========

.browserupgrade {
  width            : 100vw;
  padding-top      : 15px;
  padding-bottom   : 15px;
  color            : #FFF;
  background-color : #eb4d4b;
  text-align       : center;

  a {
    color           : #FFF;
    text-decoration : underline;

    &:hover {
      text-decoration : none;
    }
  }
}

// ==========[ GRID ]==========

@media
  (min-width : 1400px) {
  .container {
    max-width : 1386px;
  }
}

// ==========[ WEBINAR FORM ]==========

#FormBuilder_webinar {
  input[type="checkbox"] {
    min-height: 0!important;
  }
}

#webinarForm {
  iframe {
    @media (max-width: 424px) {
      width: 242px !important;
    }
  }
}

// ==========[ CUSTOM ]==========

#FormBuilder_contactformulier,
#FormBuilder_newsletter_popup {
  .google-terms {
    font-size: 65%;
    color: #9ca3af;
    a {
      color: #9ca3af;
      text-decoration: underline;
      &:hover {
        color: #9ca3af;
        text-decoration: none;
      }
    }
  }
}

#FormBuilder_newsletter_popup {
  .Inputfield {
    margin-bottom: 0 !important;
  }
}

// ==========[ JOBS ]==========

#jobs {
  .job-title {
    font-size: 26px;
  }

  .quote {
    min-height: 120px;
  }
}

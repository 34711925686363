.carousel {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 5px;
}

.slider_wrapper {
    margin: auto auto;
    max-width: 70vh;
    overflow: hidden;
}

.slider {
    display: flex;
    transition: transform 0.5s ease;
}

.slide {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
    border-radius: 5px;
}

.thumbnails {
    border-radius: 5px;
    margin-top: 10px;
    transition: transform 0.5s ease;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
}

.thumbnail {
    width: 100%;
    cursor: pointer;
    border: 3px solid transparent;
    border-radius: 5px;
}

.thumbnail.active {
    border-color: #6ab4d6;
}

.load_more {
    width: 100%;
    cursor: pointer;
    border: 3px solid transparent;
    border-radius: 5px;
    font-size: 1.5rem;
    display: flex;
    color: #9e9e9e;
    padding: 0;
    position: relative;
}

.load_more > img,
.load_more > div {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.load_more > div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
}

.btns {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    margin-top: 10px;
}

.next {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border: 3px solid transparent;
    border-radius: 50%;
    background-color: #6ab4d6;
    color: white;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prev {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border: 3px solid transparent;
    border-radius: 50%;
    background-color: #6ab4d6;
    color: white;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
}

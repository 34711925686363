// ==========[ GENERAL ]==========

.ie-width-fix {
  max-width : 100%;
}

* {
  font-feature-settings   : "kern" 1;
  font-family             : $ff-allumi-std;
  font-kerning            : normal;
  -webkit-font-smoothing  : antialiased;
  -moz-osx-font-smoothing : grayscale;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin  : 0;
  padding : 0;
}

h1 {
  font-size   : 48px; //56px
  font-weight : 400;

  &.small-title {
    font-size   : 40px;
    line-height : 45px;
  }
}

h2 {
  font-size   : 35px;
  font-weight : 600;
  line-height : 44px;

  b {
    font-weight : 700;
  }

  &.quote {
    font-family    : $ff-playfair-display;
    font-weight    : normal;
    line-height    : 47px;
    letter-spacing : 0.7px;
  }

  &.small-title {
    font-weight : 100;
  }
}

h3 {
  font-size   : 24px;
  font-weight : 900;
  line-height : 31px;
}

h4 {
  color       : $white;
  font-size   : 22px;
  font-weight : normal;
  line-height : 28px;
}

h5 {}

.cms,
p {
  color       : $black;
  font-size   : 18px;
  font-weight : 400;
  line-height : 28px;

  h2 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom : 14px;
    @media (min-width: 768px) {
      font-size: 29px;
      line-height: 44px;
    }
  }

  a {
    color           : $blue-light;
    text-decoration : none;

    i,
    svg {
      font-size : 13px;
    }

    &:hover {
      color           : $blue-dark;
      text-decoration : none;
    }
  }

  pre {
    padding          : 4px 10px;
    color            : $grey-dark;
    background-color : $grey-light;
  }

  &.blue-bold {
    b,
    strong {
      color : $blue-dark;
    }
  }

  //Fixing images
  img.align_left {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    max-width: 100%;
  }
  img.align_right {
    float: right;
    margin-left: 15px;
    margin-bottom: 15px;
    max-width: 100%;
  }
  img.align_center {
    margin-left: auto!important;
    margin-right: auto!important;
    display: block;
    max-width: 100%;
  }

}

.play-icon {
  display          : flex;
  position         : absolute;
  top              : 0;
  right            : 0;
  bottom           : 0;
  left             : 0;
  color            : white;
  background-color : rgba($white, .8);
  align-items      : center;
  font-size        : 64px;
  flex-direction   : column;
  justify-content  : center;
  transition       : font-size 1s;

  &:hover {
    font-size : 72px;
  }
}

.titel-link:hover{
  text-decoration: none;
}

.at-resp-share-element {
  .at-share-btn {
    &:hover {
      transform: translateY(0px) !important;
    }
  }
}

.at-style-responsive {
  .at-share-btn {
    margin-right: 10px!important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

/*.glass {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.7px);
  -webkit-backdrop-filter: blur(3.7px);
  border: 1px solid rgba(255, 255, 255, 0.22);
}*/

// ==========[ HEADER || NAV ]==========

.top-bar {
  padding-top      : 16px;
  padding-bottom   : 36px;
  background-color : $blue-light;

   .language-buttons {
     a {
       font-size: 16px !important;
     }
   }

  a {
    position    : relative;
    margin      : 0 20px;
    color       : $white;
    font-size   : 20px;
    font-weight : 600;

    // Job badge in top-menu
    .badge {
      position         : relative;
      bottom           : 8px;
      width            : 14px;
      height           : 14px;
      color            : $white;
      border-radius    : 999px;
      background-color : $red;
      text-align       : center;
      font-size        : 10px;
      font-weight      : bold;
    }

    &:hover {
      color           : $blue;
      text-decoration : none;
    }

    &:last-child {
      margin : 0px 0px 0px 20px;
    }

    &.active {
      color : $blue;
    }
  }
}

.navbar {
  z-index    : 1;
  height     : 100px;
  margin-top : -20px;
  padding    : 0 65px;
  background : $white;
  box-shadow : 0px 3px 6px rgba(0, 0, 0, 0.16);

  .navbar-brand {
    width : 160px;

    img {
      width : 100%;
      @media screen and (min-width: 768px ) {
        width: 80%;
        padding: 10px;
        margin-top: 10px;
      }
      @media screen and (min-width: 992px ) {
        width: 100%;
        padding: 20px;
        margin-top: 0;
      }
    }
  }

  .nav-item {
    margin  : 0;
    padding : 0;

    .nav-link {
      margin      : 0;
      padding     : 0 15px;
      color       : $blue;
      font-size   : 18px;
      font-weight : 600;

      .svg-inline--fa {
        font-size: 16px;
        padding-top: 2px;
      }

      @media screen and (min-width: 1200px ) {
        font-size   : 24px;
        padding     : 0 20px;

        .svg-inline--fa {
          font-size: 20px;
          padding-top: 1px;
        }
      }

      &:hover {
        color : $blue-light;
      }

    }
    .dropdown-menu{
      border-radius: 0;
      margin: 0;
      padding-top: 20px;
      border: none;
      .dropdown-item{
        color : $blue-dark;
        &:hover {
          color : $blue-light;
        }
        &.active{
          color : $blue-light;
        }
      }
    }

    &:last-child {
      .nav-link {
        padding : 0 0 0 20px;
      }
    }

    &:first-child {
      .nav-link {
        padding : 0 20px 0 0;
      }
    }

    &.active {
      .nav-link {
        color : $blue-light;
      }
    }

    &:hover {
      .dropdown-menu {
        display: block;
      }

      .svg-inline--fa {
        transform: rotate(180deg);
        transition: .5s;
      }
    }

    .dropdown-toggle::after {
      display: none;
    }
  }
}

// ==========[ FOOTER ]==========

footer {
  background-image    : url(/dist/assets/images/bg-element.svg);
  background-color    : $blue;
  background-repeat   : no-repeat;
  background-position : right -40px center;
  background-size     : auto 110%;

  .footer-menu {
    a {
      color        : $white;
      font-size    : 18px;
      font-weight  : bold;

      &:last-child {
        margin-right : 0;
      }

      &:hover {
        color           : $blue-light;
        text-decoration : none;
      }

      .badge {
        position         : relative;
        bottom           : 8px;
        width            : 14px;
        height           : 14px;
        color            : #fff;
        border-radius    : 999px;
        background-color : red;
        text-align       : center;
        font-size        : 10px;
        font-weight      : 700;
      }
    }
  }

  .footer-socials {
    display         : flex;
    align-items     : center;
    justify-content : flex-end;

    a {
      display          : flex;
      width            : 35px;
      height           : 35px;
      margin-right     : 12px;
      color            : $blue-dark;
      border-radius    : 3px;
      background-color : $white;
      align-items      : center;
      font-size        : 18px;
      justify-content  : center;

      &:last-child {
        margin-right : 0;
      }

      &:hover {
        background-color : $blue-light;
        color: $white;
        transition: .2s all ease-in-out;

        > svg {
          fill: $white;
        }
      }

      svg {
        fill: $blue-dark;
      }

      svg:hover {
        fill: $white;
      }
    }
  }

  .dx-image {
    height: 100px;
  }

  .google-terms {
    font-size: 65%;
    color: #e5e5e5;
    a {
      color: #e5e5e5;
      text-decoration: underline;
      &:hover {
        color: #e5e5e5;
        text-decoration: none;
      }
    }
  }
}

.bottom-bar {
  color : $white;

  a {
    padding   : 0 5px;
    color     : $white;
    font-size : 16px;
  }
}

.logo-bar {
  .footer-image {
    img {
      height : 70px;
      margin: 10px 0;
    }
  }
}

// ==========[ HOME ]==========

.home__diensten {
  z-index    : 1;
  margin-top: -70px;
  padding: 75px 75px 0px 75px;
  background : $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  .dienst {
    img {
      margin-bottom : 16px;
    }
    p{
      font-size: 15px;
    }
    a:hover{
      text-decoration: none;
    }
    .subpage-list{
      padding: 0;
      list-style: none;
      a{
        color: $blue-light;
        text-decoration: none;
        &:hover{
          color: $blue-dark;
        }
        .icon{
          vertical-align: baseline;
        }
      }
    }
  }
}

.case-block {
  position : relative;

  .image {
    position            : absolute;
    top                 : 0;
    bottom              : 0;
    background-repeat   : no-repeat;
    background-position : center;
    background-size     : cover;

    &.left {
      right : 50%;
      left  : 0;
    }

    &.right {
      right : 0;
      left  : 50%;
    }
  }

  .bg-block {
    position            : absolute;
    top                 : 0;
    bottom              : 0;
    background-image    : url(../../../dist/assets/images/bg-element.svg);
    background-color    : $blue;
    background-repeat   : no-repeat;
    background-position : right -20px bottom -125px;
    background-size     : 50% auto;

    &.left {
      right : 50%;
      left  : 0;
    }

    &.right {
      right : 0;
      left  : 50%;
    }
  }
  .bg-block-grey{
    position            : absolute;
    top                 : 0;
    bottom              : 0;
    background-color    : $grey-light;
    background-repeat   : no-repeat;
    background-position : right -20px bottom -125px;
    background-size     : 50% auto;

    &.left {
      right : 50%;
      left  : 0;
    }

    &.right {
      right : 0;
      left  : 50%;
    }
  }
}
.cases-grid{

  img{
    &:hover {
      filter: grayscale(0%);
      transition: .2s all ease-in-out;
    }
  }
}

.small-text {
  font-size: 1rem;
  font-weight: 300;
}

.references-slider {
  position: relative;

  @media (max-width: 1470px) {
    max-width: calc(100% - 50px);
    margin: 0 auto;
  }

  @media (max-width: 1420px) {
    max-width: calc(100% - 70px);
  }

  @media (max-width: 1399px) {
    width: calc(100% - 26px);
  }

  @media (max-width: 1018px) {
    width: calc(100% - 50px);
  }

  @media (max-width: 575px) {
    width: calc(100% - 70px);
  }

  .slick-slide {
    padding: 0 50px;
    float: none;
    display: inline-block;
    vertical-align: middle;
    text-align: center;

    img {
      vertical-align: middle;
      display: inline-block;

      &:hover {
        filter: grayscale(0%);
        transition: .2s all ease-in-out;
      }
    }
  }

  .slider-controls {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);    right: -50px;
    left: -50px;
    align-items: center;
    justify-content: space-between;

    a {
      z-index: 999;

      &.prev-slide {
        transform: rotate(180deg);
      }
    }
  }
}

// ==========[ BLOG ]==========

#blog-top {
  .list {
    background-color: $white;
    padding-left: 40px;
    height: max-content;
    @media (min-width: 1440px) {
      padding-left: 65px;
    }

    .list-items {
      list-style-type: none;
      padding: 0;
    }
  }
}
.blog-grid {
  display         : flex;
  align-items     : stretch;
  justify-content : space-between;

  .blog-item {
    display          : flex;
    padding          : 80px 55px;
    border-right     : 2px solid rgba($black, .8);
    background-color : $white;
    align-items      : center;
    flex             : 0 0 33.33333%;
    flex-direction   : column;
    justify-content  : center;

    .date {
      color     : rgba($black, .6);
      font-size : 16px;
    }

    &:last-child {
      border-right : 0;
    }
  }
}

.event {
  a {
    text-decoration: none;
  }
}

#service-related-blogs {
  .blog-item {
    border: 1px solid $grey-light;
    &:hover {
      .card-footer-link a {
        color: $blue-dark;
        transition: .3s ease-in-out;
      }
    }
  }
}

.social-block {
  .social-block-wrapper {
    padding         : 0 4px;
    span{
      width:100% !important;
      iframe{
        width: 100% !important;
      }
    }

    .blog-item {
      border-bottom     : 2px solid rgba($grey, .8);
       background-color : $white;
       flex: 33.33%;
       box-shadow: rgba(0, 0, 0, 0.05) 0 1px 2px 0;
       @media(min-width: 1025px){
         min-height: 175px;
       }
      @media(min-width: 1440px){
        min-height: 160px;
      }
      .date {
        color     : rgba($black, .6);
        font-size : 14px;
      }
      .event-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        @media(min-width: 1199px){
          height: auto;
          object-fit: cover;
        }
      }
      h3{
        font-size: 16px;
      }
      &:last-of-type{
        margin-bottom: 0 !important;
        border-bottom: none;
      }
    }
    &:last-of-type{
      border-bottom: none;
    }
    @media(max-width: 767px){
      padding: 0 15px;
    }
    &.fb{
      .inner{
        .fb_item{
          background-color: $white;
          border-bottom     : 2px solid rgba($grey, .8);
          .date{
            color: $grey;
          }
          .cms{
            font-size: 14px;
            line-height: 21px;
            opacity: .8;
          }
          .action-item{
            a{
              font-size: 14px;
              color: $grey;
              &:hover{
                text-decoration: none;
                color: $blue;
              }
            }
          }
          a.well-link{
            .well{
              background-color: rgba($grey-light, 1%);;
              font-size: 12px;
              b{
                text-decoration: underline;
              }
              &:hover{
                text-decoration: none;
              }
            }
            &:hover{
              text-decoration: none;
            }
          }
        }
      }
    }

  }
}

// ==========[ CONTACT ]==========

.map {
  width            : 100%;
  padding-top      : 75%;
  background-color : $grey-light;
}

// ==========[ CASES ]==========

.arrow-text {
  display         : flex;
  align-items     : flex-start;
  justify-content : flex-start;

  img {
    margin-right : 25px;
  }

  ul li {
    margin-bottom : 16px;

    &:last-child {
      margin-bottom : 0;
    }
  }
}

.case-cta {
  display          : flex;
  background-color : $white;
  justify-content  : space-between;
  flex-wrap: wrap;

  .left,
  .right {
    .inner {
      display         : flex;
      width           : 100%;
      height          : 100%;
      padding         : 90px 100px;
      align-items     : center;
      flex-direction  : column;
      justify-content : center;
    }
  }

  .left {
    border-right : 2px solid rgba($black, .8);
    flex         : 0 0 66.666%;
    max-width: 66.6%;
  }

  .right {
    flex : 0 0 33.333%;
    max-width: 33.3%
  }
  .center-share{
    padding : 90px 50px;
    max-width: 960px;

    a.a2a_button_facebook{
      background-color: #3e5b98;
    }
    a.a2a_button_twitter{
      background-color: #4da7de;
    }
    a.a2a_button_linkedin{
      background-color: #3371b7;
    }
    a.a2a_button_email{
      background-color: #07002d;
    }
    a.share-link{
      text-align: left;
      .icon{
        font-size: 24px;
      }
      span{
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
}

.cases-slider {
  .slick-slide {
    height : 100%;

    div {
      height : 100%;
    }

    .slide-wrapper {
      height : 100% !important;
    }

    .case-block {
      height : 100% !important;
    }
  }

  .slick-dots {
    position : absolute;
    bottom   : 35px;

    li {
      button {
        &::before {
          color     : $blue-light;
          opacity   : 1;
          font-size : 28px;
        }
      }

      &.slick-active {
        button {
          &::before {
            color : $white;
          }
        }
      }
    }
  }
}

.top-case-card {
  transition: .3s all ease-in-out;
  &:hover {
    transform: scale(1.05);
    .cms a {
      color: #2f287b;
      text-decoration: none;
    }
  }
}

.case-body {
  p, ul, li {
    color: #d4d4d4;
    font-size: 18px;
  }
  h2, h3, h4 {
    color: #d4d4d4;
  }
  & p a:hover {
    color: $white;
  }
}

.case-logo-image {
  @media (max-width: 991px) {
    width: 50%;
  }
}

.case-arrow {
  height: 10%;
  bottom: -10px;
  right: 5px;
  @media (max-width: 425px) and (min-width: 376px) {
    height: 15%;
  }
  @media (max-width: 767px) and (min-width: 426px) {
    height: 20%;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    height: 35%;
  }
  @media (min-width: 992px) {
    height: 50%;
    bottom: -30px;
  }
}

.case-image {
  background-position: left center;
  background-size: cover;
}

.quote-image {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  @media (min-width: 1200px) {
    position: absolute;
  }
}

.case-person-box {
  .case-person-img {
    width               : 50px;
    height              : 50px;
    border-radius       : 50%;
    background-position : 40% 50%;
    background-size     : cover;
    border: 1px solid #DCDCFF;
  }
  .case-person-text {
    span  {
      font-weight: lighter;
    }
  }
}

.quote-card {
  p {
    font-size: 16px;
  }
}

// ==========[ SERVICES ]==========
.border-blue{
  border: $blue-dark;
}
.services-grid-blue{
  display         : flex;
  margin-top      : calc(-255px / 2);
  justify-content : center;
  flex-wrap       : wrap;
  .service-blue-item{
    @include           quart-transition(400ms);
    display          : block;
    position         : relative;
    background-color : $blue-light;
    flex             : 1 0 auto;
    font-size        :1rem;
    text-decoration  : none;
    //width: 100%;
    p{
      color: $white;
    }
    @media
    (max-width : 600px) {
      max-width: 80%;
    }
    @media
    only screen and (max-width: 900px) and (min-width: 600px) {
      max-width: 40%;
    }
    @media
    (min-width : 2000px) {
     // max-width: 50%;
    }
    .service-icon {
      max-height    : 50%;
      margin-bottom : 25px;
    }
    &:hover {
      background-color : $blue;
      text-underline-style: none;
    }
  }
}
.services-grid {
  display         : flex;
  margin-top      : calc(-255px / 2);
  align-items     : flex-start;
  justify-content : center;
  flex-wrap       : wrap;
  .service-item {
    @include           quart-transition(400ms);
    display          : block;
    position         : relative;
    margin-right     : 20px;
    margin-bottom    : 22px;
    background-color : $blue-light;
    box-shadow       : 0px 3px 6px rgba(0, 0, 0, 0.16);
    flex             : 1 0 auto;
    max-width: 16%;
    width: 100%;
    @media
    (max-width : 1200px) {
      max-width: 18%;
    }
    .inner {
      display         : flex;
      position        : absolute;
      top             : 0;
      right           : 0;
      bottom          : 0;
      left            : 0;
      padding         : 25px;
      align-items     : center;
      flex-direction  : column;
      justify-content : center;

      .service-icon {
        max-height    : 50%;
        margin-bottom : 25px;
        @media
        (max-width : 1200px) {
          margin-bottom : 0;
        }
      }

      .text {
        display         : flex;
        align-items     : center;
        justify-content : center;

        h5 {
          color       : $white;
          font-size   : 18px;
          font-weight : normal;
        }
      }
    }
    
    &:before {
      content     : '';
      width       : 100%;
      float       : left;
      padding-top : 100%;
    }

    &:hover {
      background-color : $blue;
    }
  }

  &.white {
    margin-top : 0;

    .service-item {
      background-color : $white;
      &:hover {
        transform: scale(1.1);
      }

      .inner {
        .text {
          h5 {
            font-size: 18px;
            color : $black;
          }
        }
      }
    }
  }
}

.service-item-hover {
  &:hover {
    background-color: $blue-dark;
    color: $white;
    transition: all .4s cubic-bezier(.77,0,.175,1);
    cursor: pointer;
  }
}

.voordelen-block {
  display          : flex;
  position         : relative;
  width            : 100%;
  padding-top      : 120px;
  padding-right    : 110px;
  padding-bottom   : 90px;
  padding-left     : 110px;
  background-color : $blue-light;
  align-items      : center;
  flex-direction   : column;

  .wrapper {
    display         : flex;
    align-items     : flex-start;
    justify-content : space-between;

    .voordeel-item {
      display         : flex;
      align-items     : center;
      flex            : 0 0 27.5%;
      flex-direction  : column;
      justify-content : flex-end;
    }
  }

  .button-wrapper {
    position : absolute;
    right    : 0;
    bottom   : -5px;
    left     : 0;
  }

  @media (min-width: 768px) {
    .button-wrapper {
      position: absolute;
      right: 0;
      bottom: -30px;
      left: 0;
    }
  }
}

.image-fit {
  object-fit: cover;
  max-height: 400px;
  width: 100%;
}

// ==========[ OVER ONS ]==========

.about-grid-bg {
  background-color : rgba($black, .8);

  .about-grid {
    display         : flex;
    width           : 100%;
    align-items     : stretch;
    flex-wrap       : wrap;
    justify-content : space-between;

    .about-item {
      margin-right     : 1px;
      margin-bottom    : 1px;
      background-color : $white;
      flex-basis       : calc(33.333% - 1.333px);
      // flex             : 0 0 calc(33.333% - 1.333px);

      .inner {
        padding : 50px 60px;
      }

      &:nth-child(3n) {
        margin-right : 0;
        flex         : 0 0 33.333%;
      }

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        margin-bottom : 0;
      }
    }
  }
}

.team-member-image {
  width               : 100%;
  padding-top         : 100%;
  background-repeat   : no-repeat;
  background-position : top;
  background-size     : auto 100%;
}

.adviesraad-slider {
  position : relative;

  .slick-slide {
    margin-left : 18px;
  }

  .slick-list {
    margin-left : -18px;
  }

  .slick-track {
    display : flex;

    .slick-slide {
      display : flex;
      height  : auto;
    }
  }

  .slider-controls {
    display         : flex;
    position        : absolute;
    top             : calc(50% - 21px);
    right           : -11px;
    left            : -11px;
    align-items     : center;
    justify-content : space-between;

    a {
      z-index : 999;

      &.prev-slide {
        transform : rotate(180deg);
      }
    }
  }

  .slide-wrapper {
    height         : 100%;
    padding-top    : 125px;
    padding-bottom : 25px;

    .adviesraad-block {
      display          : flex;
      height           : 100%;
      padding-right    : 90px;
      padding-bottom   : 60px;
      padding-left     : 90px;
      background-color : $white;
      align-items      : flex-start;
      flex-direction   : column;
      justify-content  : flex-start;

      .avatar {
        width         : 175px;
        height        : 175px;
        margin        : 0;
        margin-top    : -87px;
        margin-bottom : 60px;
        overflow      : hidden;
        align-self    : center;
        transform     : rotate(45deg);

        .avatar-inner {
          position        : absolute;
          top             : -30px;
          left            : 2px;
          width           : 275px;
          height          : 275px;
          margin          : 0 0 0 -49px;
          background-size : cover;
          transform       : rotate(-45deg);
        }
      }
    }
  }

  .slick-dots {
    li {
      width  : 6px;
      height : 6px;
      margin : 0px 4px;

      button {
        width            : 6px;
        height           : 6px;
        background-color : $grey;
        transform        : rotate(45deg);

        &::before {
          content : "";
        }
      }

      &.slick-active {
        button {
          background-color : $blue-dark;
        }
      }
    }
  }
}

// ==========[ BLOG DETAIL ]==========

.blog-detail {
  width : 100%;

  .image {
    width               : 100%;
    height              : 500px;
    background-repeat   : no-repeat;
    background-position : center;
    background-size     : cover;
  }

  .date {
    color : rgba($black, .6);
  }
}

.blog-image {
  min-width : 100%;
}

// ==========[ BLOG INFINITE MARKUP ]==========
.infinite-hide-card {
  height: auto;
  width: 100%;
  display: none;
}

// ==========[ 404 ]==========

.not-found {
  h2,
  h3 {
    color : $blue !important;
  }
}

.lost-image {
  height: 800px;
  object-fit: cover;
}

.diensten {
  padding: 30px 10px 0px 10px;
  background : $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  @media (min-width: 425px) {
    padding: 55px 35px 0px 35px;
  }
  @media (min-width: 768px) {
    padding: 65px 65px 0px 65px;
  }

  .dienst {
    img {
      margin-bottom : 16px;
    }
    a:hover{
      text-decoration: none;
    }
    .subpage-list{
      padding: 0;
      list-style: none;
      a{
        color: $blue-light;
        text-decoration: none;
        &:hover{
          color: $blue-dark;
        }
        .icon{
          vertical-align: baseline;
        }
      }
    }
  }
}


.socials {
  display         : flex;
  align-items     : center;

  a {
    display          : flex;
    width            : 35px;
    height           : 35px;
    margin-right     : 12px;
    color            : $white;
    border-radius    : 3px;
    background-color : $blue-light;
    align-items      : center;
    font-size        : 18px;
    justify-content  : center;

    &:last-child {
      margin-right : 0;
    }

    &:hover {
      background-color : $white;
      border: 1px solid $blue-light;
      color:  $blue-dark;
      transition:  .2s all ease-in-out;

      > svg {
        fill: $blue-dark;
      }
    }

    svg {
      fill: $white;
    }

    svg:hover {
      fill: $blue-dark;
    }
  }
}

// ==========[ MODAL FR AND EN ]==========

.modal_language {
  p {
    font-size: 1rem;
  }
  .top-img-modal {
    height: 250px;
    object-fit: cover;
  }
  .top-container {
    z-index: 10;
    margin-top: 80px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.8;
  }
  .mid-container {
    z-index: 10;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    opacity: 0.8;
  }
  .modal-image {
    width: 60%;
  }
  .last-container {
    li {
      list-style: none;
    }
    a {
      color: black !important;
      text-decoration: none;
    }
  }
  .footer-socials {
    display         : flex;
    align-items     : center;

    a {
      display          : flex;
      width            : 35px;
      height           : 35px;
      margin-right     : 12px;
      color            : $white !important;
      border-radius    : 3px;
      background-color : black;
      align-items      : center;
      font-size        : 18px;
      justify-content  : center;

      &:last-child {
        margin-right : 0;
      }

      &:hover {
        background-color : $white;
        color:  black !important;
        transition: .2s all ease-in-out;

        > svg {
          fill: black;
        }
      }

      svg {
        fill: $white;
      }

      svg:hover {
        fill: black;
      }
    }
  }
}

//********CUSTOM SIZES FOR IMAGES CMS********//

.img-sm {
  max-width: 250px;
}
.img-md {
  max-width: 350px;
}

@media (min-width : 768px) {
  .img-lg {
    max-width: 450px;
  }
  .img-xl {
    max-width: 550px;
  }
}

// ==========[ WEBINARS ]==========

#intro {
  background-color: $grey-light;

  .list {
    background-color: $white;
    padding-left: 40px;
    height: max-content;
    @media (min-width: 1440px) {
      padding-left: 65px;
    }

    .list-items {
      list-style-type: none;
      padding: 0;
    }
  }

  .cta-button {
    @media (max-width: 767px) {
      .btn {
        width: 75% !important;
      }
    }

    a {
      color: $white;
      text-decoration: none;

      &:hover {
        color: $blue-dark;
      }
    }
  }
}

#share-socials {
  background-color: $blue-light;
}

// ==========[ Technology content ]==========

.category-link {
  &:hover {
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  .technology-image {
    width: 100%;
  }
}

// ==========[ PIM Extern content ]==========

.pim-extern-content {
  a {
    color: white;
    &:hover {
      color: $blue-dark;
    }
  }
}

.pim-links {
  a {
    color: $black !important;
  }
}

// ==========[ Landing page Function Content ]==========
.function-item {
  transition: all .2s ease-in-out;
  a {
    text-decoration: none;
    color: black;
  }
  &:hover {
    transform: scale(1.1);
  }
}

.image-landingpage {
  object-fit: cover;
  width: 100%;
  height: auto;
  @media (min-width: 992px) {
    height: 500px;
    object-position: center;
  }
}

// ==========[ Apply this to p tag in CMS if there is a iframe ]==========
.iframe-box {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

// ==========[ Apply this class to iframe tag in CMS ]==========
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

// ==========[ EVENTS / EVENT ]==========

.event-content {
  .event-title {
    font-size: 36px;
  }
}
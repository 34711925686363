// ==========[ FONTS ]==========

$ff-allumi-std       : 'allumi-std', sans-serif;
$ff-playfair-display : 'Playfair Display', serif;

// ==========[ COLORS ]==========

$white         : #FFFFFF;
$grey-light    : #F0F0F0;
$grey          : #939598;
$grey-dark     : #414042;
$black         : #000000;

$red           : #FF0000;

$blue-light    : #63ABCC;
$blue          : #32529D;
$blue-dark     : #2F287B;

// ==========[ MIXINS ]==========

@mixin boxshad() {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

@mixin softshadow() {
  box-shadow: 0px 10px 30px -5px rgba(0,0,0,.3);
}

@mixin quart-transition($duration) {
  transition: all $duration cubic-bezier(0.770, 0.000, 0.175, 1.000);
}

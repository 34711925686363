// ==========[ BUTTONS ]==========

.btn {
  padding        : 16px 30px 16px;
  border-radius  : 0;
  text-transform : uppercase;
  font-size      : 20px;
  font-weight    : normal;
  letter-spacing : 0.04em;
}

.btn-arrow {
  position         : relative;
  padding-top      : 13px;
  padding-right    : 45px;
  padding-bottom   : 12px;

  &--secondary {
    color          : $white;
    border-top     : 2px solid $white;
    border-bottom  : 2px solid $white;
    border-left    : 2px solid $white;

    &:hover {
      color            : $blue-dark;
      background-color : $white;
    }
  }

  &--primary {
    color            : $white;
    border-top       : 2px solid $blue-dark;
    border-bottom    : 2px solid $blue-dark;
    border-left      : 2px solid $blue-dark;
    background-color : $blue-dark;

    &:hover {
      color            : $blue-dark;
      background-color : $white;
    }
  }
  &--secondary-border {
    color          : $white;
    border-top     : 2px solid $blue-dark;
    border-bottom  : 2px solid $blue-dark;
    border-left    : 2px solid $blue-dark;

    &:hover {
      color            : $blue-dark;
      background-color : $white;
    }
  }

  img {
    z-index  : 999;
    position : absolute;
    top      : -2px;
    right    : -40px;
    height   : 59px;
  }
}

.btn-link {
  color           : $blue-light;
  text-decoration : underline;

  i,
  svg {
    font-size : 13px;
  }

  &:hover {
    color           : $blue-dark;
    text-decoration : none;
  }
}

.custom-block-btn{
  color: $black;
  text-transform: uppercase;
  text-align: right;
  align-self: flex-end;
  img{
    width: 11px;
    vertical-align:baseline;
  }
  &:hover{
    color: $blue;
    text-decoration: none;
  }
}

.custom-block-btn-back, .custom-block-btn-center {
  color: $black;
  text-transform: uppercase;
  img{
    width: 11px;
    vertical-align:baseline;
  }
  &:hover{
    color: $blue;
    text-decoration: none;
  }
}

// ==========[ UTILITY CLASSES ]==========

.c-black {
  color : $black !important;
}

.c-white {
  color : $white !important;

  a,
  i,
  p {
    color : $white !important;
  }
}

.c-blue-dark {
  color : $blue-dark !important;
}

.c-blue {
  color : $blue !important;
}

.c-blue-light {
  color : $blue-light !important;
}

.bg-blue-dark {
  background-color : $blue-dark;
}

.bg-blue {
  background-color : $blue;
}

.bg-blue-light {
  background-color : $blue-light;
}

.bg-grey {
  // background-color : $grey-light;
  background-color : #f6f6f6;
}

.text-shadow {
  text-shadow: 1px 1px #44403c;
}

.shadow-md {
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1), 0 4px 6px -4px rgba(0,0,0,0.1);
}

.reference {
  h2 {
    font-size: 17px;
    @media (min-width: 992px) {
      font-size: 22px;
    }
    @media (min-width: 1440px) {
      font-size: 30px;
    }
  }
}

.big-margin-top {
  margin-top: 45px;
  @media (min-width: 992px) {
    margin-top: 160px;
  }
  @media (min-width: 1386px) {
    margin-top: 200px;
  }
}

.bg-element {
  background-image  : url(/dist/assets/images/bg-element.svg);
  background-repeat : no-repeat;
  background-size   : auto 75%;

  &.bottom-left {
    background-position : left 20vw bottom -50px;
  }

  &.bottom-right {
    background-position : right -35px bottom -150px;
  }

  &.large {
    background-size : 66% auto;
  }
}

.quote-mark {
  color       : $white;
  opacity     : 0.5;
  font-family : $ff-playfair-display;
  font-size   : 85px;
}

.person-img {
  position : absolute;
  bottom   : calc(0 - #{6rem});
}

.jobs-img {
  position : absolute;
  bottom   : -3rem;
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
}

.text-box {
  padding : 85px 55px;
}

.softshadow {
  @include softshadow;
}

.services-item-subtitle {
  color       : $white;
  font-size   : 24px;
  font-weight : 900;
  line-height : 45px;
}

.services-item-title {
  color       : $white;
  font-size   : 40px;
  font-weight : 900;
  line-height : 45px;
}

.blue-filler {
  width            : 100%;
  background-color : $blue;
}

.grijs {
  filter: grayscale(100%);

}

// ==========[ BLOCKS || ITEMS ]==========

.header-block {
  display             : flex;
  position            : relative;
  min-height          : 350px;
  margin-top          : -80px;
  padding-top         : 80px;
  background-color    : $blue-light;
  background-repeat   : no-repeat;
  background-position : center 40%;
  background-size     : cover;
  text-shadow         : 0 0 6px rgba(0,0,0,.3);
  align-items         : center;
  justify-content     : flex-start;

  &.header-services {
    position   : relative;
    min-height : 450px;
  }

  .services-top-title {
    color       : $white;
    font-size   : 24px;
    font-weight : 900;
    line-height : 45px;

    span {
      color : $blue-light;
    }
  }

  .services-title {
    color       : $white;
    font-size   : 40px;
    font-weight : 900;
    line-height : 45px;
  }

  .services-subtitle {
    color       : $white;
    font-size   : 35px;
    font-weight : 100 !important;
    line-height : 45px;
  }

  .header-block-overlay {
    position         : absolute;
    top              : 0;
    right            : 0;
    bottom           : 0;
    left             : 0;
    background-color : hsla(0,0%,20%,.5);
  }

  .cms {
    color       : $white !important;
    font-size   : 18px;
    font-weight : 400;
    line-height : 28px;

    p {
      color : $white;
    }
  }

  .header-breadcrumb {
    position : absolute;
    top      : 100px;
    right    : 0;
    left     : 0;
    width    : 1386px;
    margin   : 0 auto;
    max-width: 100%;
    ul {
      padding    : 0;
      color      : $white;
      font-size  : 14px;
      list-style : none;

      li {
        display     : inline-block;
        color       : $white;
        font-weight : 300;

        a {
          color           : $white;
          text-decoration : underline;
          font-weight     : 300;
        }
      }
    }
  }
  .header-copyright{
    position: absolute;
    bottom: 5px;
    right: 10px;
    opacity: .7;
  }
}

.header-title {
  text-align : center;

  h5 {
    color       : $white;
    font-size   : 24px;
    font-weight : 900;
    line-height : 45px;
  }

  h1 {
    color       : $white;
    font-size   : 40px;
    font-weight : 900;
    line-height : 45px;
  }

  h2 {
    color       : $white;
    font-size   : 35px;
    font-weight : 200;
    line-height : 45px;
  }
}

.speech-bubble {
  @include        quart-transition(400ms);
  position      : relative;
  padding       : 36px 15px;
  border-radius : 999px;
  background    : $blue-light;

  &:after {
    @include           quart-transition(400ms);
    content          : '';
    position         : absolute;
    bottom           : 0;
    left             : 50%;
    width            : 0;
    height           : 0;
    margin-bottom    : -15px;
    margin-left      : -7.5px;
    border           : 15px solid transparent;
    border-right     : 0;
    border-bottom    : 0;
    border-top-color : $blue-light;
  }

  &:hover {
    background      : $blue;
    text-decoration : none;

    &:after {
      border-top-color : $blue;
    }
  }
}

.chat-bot {
  .container {
    position : relative;
  }

  .speech-bubble {
    position : absolute;
    right    : 0;
    bottom   : 33px;
  }
}

.case-item {
  display : block;
  padding : 30px;

  .person {
    width               : 65px;
    height              : 65px;
    border-radius       : 999px;
    background-position : center;
    background-size     : cover;
  }

  &:hover {
    text-decoration : none;
  }
}

.case-block {
  display          : block;
  height           : 100%;
  background-color : $white;

  @media screen and (min-width: 768px ) {
    h2 {
      font-size: 28px;
      font-weight : 600;
      line-height : 44px;
    }
  }

  .btn-case {
    display         : flex;
    color           : $blue-dark;
    text-decoration : underline;
    align-items     : center;
    font-size       : 18px;
    font-weight     : bold;

    img {
      height : 18px;
    }

    &:hover {
      text-decoration : none;
    }
  }

  &:hover {
    text-decoration : none;
  }
}

.cta-block {
  display: flex;
  padding: 55px 55px;
  background-color: $white;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  h2 {
    font-size: 18px;
    @media screen and (min-width: 768px ) {
      font-size: 20px;
    }
    @media screen and (min-width: 1440px ) {
      font-size: 24px;
    }
  }
}

.image-block {
  width               : 100%;
  padding-top         : 66%;
  background-repeat   : no-repeat;
  background-position : center;
  background-size     : cover;

  &.square {
    padding-top : 100%;
  }

  &.fill-height {
    height      : 100%;
    padding-top : auto;
  }
}

.blog-item {
  width : 100%;

  .image {
    width               : 100%;
    height              : auto !important;
    padding-top         : 50% !important;
    background-repeat   : no-repeat;
    background-position : center;
    background-size     : cover;
  }

  .date {
    color : rgba($black, .6);
  }
}

.breadcrumbs {
  display         : flex;
  position        : absolute;
  top             : 10px;
  left            : 0;
  align-items     : center;
  justify-content : flex-start;

  a {
    color           : $blue-dark;
    text-decoration : underline;
    font-size       : 12px;
    font-weight     : normal;

    &.active {
      text-decoration : none;
    }

    &:hover {
      color           : $blue-light;
      text-decoration : none;
    }
  }

  span {
    margin-right : 4px;
    margin-left  : 4px;
    font-size    : 10px;
    font-weight  : 100;
  }
}


// ==========[ POPUP ]==========
.popup{
  //display: block;
  background-color: rgba($white, .5);
  .modal-dialog{
    margin-top: 10%;
    width: 600px;
    max-width: calc(100% - 50px);
    margin-left: auto;
    margin-right: auto;
    .close{
      position: absolute;
      z-index: 3;
      right: -24px;
      top: 0;
      background-color: white;
      /* line-height: 22px; */
      padding: 5px;
      &:focus{
        outline: none;
      }
      span{
        color: $grey;
      }
    }
    .modal-content{
      border-radius: 0;
      border: 0;
      -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
      -webkit-background-clip: padding-box;
      -moz-background-clip: padding-box;
      background-clip: padding-box;

      .modal-body{
        overflow: hidden;
      }
      .modal-footer{
        border-top: 0;
      }
    }
  }
}

// ==========[ NEWSLETTER POPUP ]==========
.newsletter_popup {
  background-color: rgba($white, .5);

  .modal-dialog {

    span {
      color: $white;
    }

    .modal-content {
      border-radius: 0;
      border: 0;
      -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
      -webkit-background-clip: padding-box;
      -moz-background-clip: padding-box;
      background-clip: padding-box;
      background-color: #abdbe3;
      color : $blue-dark;
      overflow: hidden;

      .bg-img {
        right: 0;
        bottom: 0;
        height: 400px;
      }
      .close {
        z-index: 10;
        border: none;
        outline: none;
      }
      .modal-body {
        z-index: 10;
        overflow: hidden;
      }
    }
  }
}


// ==========[ Cookie management banner ]==========
.pwcmb-widget {
  background: #2f287b;
  color: white;

  .pwcmb-widget__text {
    p {
      color: white;
      font-size: 14px;
      line-height: 1.5;
    }
  }

  .pwcmb-widget__row {
    margin-top: 1em;
  }

  .pwcmb-option-wrapper {
    label {
      span {
        color: white;
      }
    }
  }

  .pwcmb-widget__button {
    color: white!important;
    margin-right: 40px;
    font-size: 12px;
    height: 45px;

    &.btn-arrow img {
      right: -25px;
      height: 45px;
    }

    &:hover {
      color: #2f287b!important;
    }
  }
}
